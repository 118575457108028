.jobShortHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.jobShort {
    margin-top: 20px;
    padding: 20px;
}

.jobShort:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, .05);
    cursor: pointer;

}