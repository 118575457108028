/* #ef4625

#68174a

#fbcccc

#fde8eb


*/

@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


.Member-Login {
    background-color: #fde8eb;
    text-align: center;
    padding: 5rem;
}

.ml-fpp {
    display: flex;
    justify-content: center;
    width: 21rem;
}

.ce-cen-ter {
    text-align: center;
    display: flex;
    justify-content: center;
}

.signUp {
    display: flex;
    justify-content: center;
}

.ml-wel {
    color: #ef4625;
    font-family: 'new_york_mediumbold';
}

.ml-mem {
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.ml-mem span {
    color: #ef4625;
}

.ml-access {
    font-family: 'sf_ui_displayregular';
}

.eu-ml {
    font-family: 'new_york_mediumbold';
}

.pass-ml {
    font-family: 'new_york_mediumbold';
}

.mobile-ml {
    font-family: 'new_york_mediumbold';
    margin-left: -210px;
    color: #68174a;
}

.con-ml {
    font-family: 'sf_ui_displayregular';
}

.input-user {
    width: 18rem;
    padding: 1rem;
    border: 1px solid gray;
    border-radius: 5px;
}

.input-pass {
    width: 18rem;
    padding: 1rem;
    border: 1px solid gray;
    border-radius: 5px;
}

.input-mobile {
    width: 21rem;
    padding: 1rem;
    border: 1px solid gray;
    border-radius: 5px;
}

.btn-comp-nbn {
    width: 21rem !important;
    padding: .6rem !important;
    background-color: white !important;
    color: #68174a !important;
    border: 1px solid gray !important;
}

.err-icn {
    text-align: center !important;
    color: #ef4625;
}

.check-labell {
    margin-right: 3.3rem !important;
    color: gray;
    width: 46%;
}

.login-err {
    color: #68174a;
    text-align: center;
}

.saveup-cen {
    color: gray;
}

.btn-labell {
    background: none;
    border: none;
    color: #ef4625;
    font-family: 'new_york_mediumbold';
}

.validatee {
    width: 21rem;
    padding: 1rem 0;
    background-color: #ef4625;
    border: 1px solid #ef4625;
    border-radius: 5px;
    font-family: 'new_york_mediumbold';
    color: white;
}

.p-signUp {
    font-size: .8rem;
    color: gray;
    margin-right: 1rem;
}

.btn-sign {
    border: none;
    background: none;
    font-family: 'sf_ui_displayregular';
    font-weight: bold;
    border-bottom: 1px solid #68174a;
    color: #68174a;
}

.link-icnn {
    color: rgb(24, 24, 221);
    font-size: 2rem !important;
}



/* @media screen and (max-width:414px) {

   
} */

@media screen and (max-width:768px) {

    .mobile-ml {
        margin-left: -170px;
    }
}

@media screen and (max-width:567px) {

    .mobile-ml {
        margin-left: -147px;
        padding: 2px;
    }
}

.btn-linkden {
    color: #0072b1 !important;
    background-color: transparent;
    width: 100%;
    font-size: 20px;
    border: transparent;
    border-radius: 5px;
}

.btn-linden-icon {
    font-size: 50px !important;
}