



.welcome{
    text-align: center;
    padding: 5rem;
    background-color: #fbcccc;
}

.icn-wel{
    color: #ef4625;
}

.wel-par{
    color: #ef4625;
}

.wel-head{
    color: #68174a;
}

.rule-exp-p-g{
    display: flex;

}
.ver-l-ine{
    border: 2px solid #68174a;
    height: 2rem;
    width: 1px;
}

.ver-l-ine-m{
    border: 2px solid #68174a;
    height: 3rem;
    width: 1px;
}

.wel-head span{
    color: #ef4625;
}

.wel-paragraph{
    color: gray;
}

.wel-btn{
    padding: 10px 25px; 
    border-radius: 10px;
    border: none;
    background-color: red;
    color: #fde8eb;
    /* margin-right: 5rem; */
    margin-left: 1.5em;
    font-family: 'new_york_mediumbold';
}

.wel-view{
    font-weight: bold;
    color: #68174a;
    border-bottom:1px solid #68174a;
}


/* rule-wel */

.rule-wel{
    padding: 5rem;
    background-color: #fde8eb;
}

.wel-rule{
    color: #68174a;
    margin-bottom: 2rem;
}

.wel-rule span{
    color: #ef4625;
}

