.render-subroles {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.role-s label {
    float: left;
    color: #68174a;
    font-weight: bold;
}

.Updates {
    background-color: #ef4625;
    border: none;
    text-align: center;
}

.btn-flex {
    display: flex;
    justify-content: space-between;
}

.disableForm {
    border-color: transparent;
    background-color: transparent;
}

.role-s select {
    padding: 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
}

@media screen and (max-width:1288px) {
    .ptt {
        height: 5rem;
    }

    .role-s label {
        height: 3rem;
    }
}

@media screen and (max-width:1200px) {
    .company-name label {
        height: 3rem;
    }

    .job-title label {
        height: 3rem;
    }
}

@media screen and (max-width:1100px) {
    .ptt {
        height: 6rem;
    }

    .org-lo label {

        height: 3rem;
    }
}

@media screen and (max-width:992px) {
    .job-titlee select {
        width: 100%;
        margin-top: 1rem;
    }

    .company-name label {
        height: unset;
    }

    .job-title label {
        height: unset;
    }

    .job-titlee input {
        margin-top: 1rem;
    }
    .display-block{
        display: block !important;
    }
    .option-of-option{
        padding: 10px !important;
        
    }
    .option-to-recognization{
        margin-left: -0.5rem;
        font-size: 10px !important;
    }
}

@media screen and (max-width:767px) {
    .org-lo label {
        height: unset;
    }

    .role-s label {
        height: 1rem;
    }
}

.subroles {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 62%;
    color: #6D6D6D;
    margin-top: 10px;
}


/* subh  */

@media screen and (max-width:769px) {
.btn-flex{
    display: flex;
    justify-content: space-around !important;

}
 .Updates, div{
    align-self: center;
    margin-left: -5px;
    /* font-size: 12px; */
}
}

@media (min-width:450px)and (max-width:570px){
    .option-to-recognization{
        margin-left: 0.5rem;
        font-size: 10px !important;
    }
}


@media (min-width:350px)and (max-width:450px){
    .option-to-recognization{
        margin-left: -0.5rem;
        font-size: 10px !important;
    }
}