@font-face {
  font-family: 'new_york_mediumbold';
  src: url('./fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
    url('./fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'sf_ui_displayregular';
  src: url('./fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
    url('./fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.navigateToBtn {
  border: 1px solid #68174a;
  background: none;
  padding: 10px 25px;
  border-radius: 10px;
  color: #68174a;
  font-family: 'new_york_mediumbold';
  width: 20%;
  text-align: center;
  margin-top: 20px;
}

.navigateToBtn:hover {
  cursor: pointer;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, .05);
}

:root {
  --columPadding: 40px 0px 400px 0px
}

h1 {
  font-size: 40px !important;
  font-family: 'new_york_mediumbold' !important;
}

h2 {
  font-size: 32px !important;
  font-family: 'new_york_mediumbold' !important;
}

h3 {
  font-size: 26px !important;
  font-family: 'new_york_mediumbold' !important;
}

h4 {
  font-size: 20px !important;
  font-family: 'new_york_mediumbold' !important;
}

h5 {
  font-size: 16px !important;
  font-family: 'new_york_mediumbold' !important;
}

h6 {
  font-size: 14px !important;
  font-family: 'new_york_mediumbold' !important;
}

p {
  font-size: 17px !important;
  font-family: 'sf_ui_displayregular' !important;
}



@media screen and (max-width:414px) {

  h1 {
    font-size: 26px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h2 {
    font-size: 20px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h3 {
    font-size: 17px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h4 {
    font-size: 16px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h5 {
    font-size: 15px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h6 {
    font-size: 13px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  p {
    font-size: 13px !important;
    font-family: 'sf_ui_displayregular' !important;
  }
}


@media screen and (max-width:991px) {

  h1 {
    font-size: 26px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h2 {
    font-size: 22px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h3 {
    font-size: 19px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h4 {
    font-size: 18px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h5 {
    font-size: 17px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h6 {
    font-size: 14px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  p {
    font-size: 13px !important;
    font-family: 'sf_ui_displayregular' !important;
  }
}



@media screen and (max-width:375px) {

  h1 {
    font-size: 25px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h2 {
    font-size: 19px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h3 {
    font-size: 16px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h4 {
    font-size: 15px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h5 {
    font-size: 14px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  h6 {
    font-size: 13px !important;
    font-family: 'new_york_mediumbold' !important;
  }

  p {
    font-size: 13px !important;
    font-family: 'sf_ui_displayregular' !important;
  }
}