


.flex-p-overview-m-k{
    background-color: #fde8eb;
    padding: 2rem;
    border-radius: 1rem;
    /* height: 80rem; */
}


@media screen and (max-width:991px) {
    .flex-p-overview-m-k {
        height: unset;
        margin-bottom: 2rem;
    }
}