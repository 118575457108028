/* #ef4625

#68174a

#fbcccc

#fde8eb


*/


@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.nb-head {
    display: flex;
    align-items: center;
    align-content: flex-start;
}
/* .Notice-Board{
    display: flex;
} */

.Notice-Board {
    padding: 5rem 5rem 5rem 5rem;
    margin: 0 auto;
    text-align: center;
}
.Notice-Board .NB-heading {
    text-align: center;
    color: #ef4625;
    background-color: #fde8eb;
    font-family: 'new_york_mediumbold';

}

.noticeImg {
    height: 75px !important;
}


.NB-main-component {
    padding: 0rem 0rem 0rem 0rem;
    background-color: #fbcccc;
    transform: skew(0deg, -5deg)
}

.co-out-com {
    transform: skew(0deg, 5deg);
}

.nb-not {
    background-color: #fde8eb;
}

.hei-height {
    height: 200px;
    background-color: #fbcccc;
    margin-top: -140px;
}

.btn-comp-nbn-n {
    background-color: #ef4625 !important;
    color: white !important;
    text-transform: capitalize !important;
    border: 1px solid #ef4625 !important;
    font-family: 'sf_ui_displayregular';
}


.btn-comp-nbn-1 {

    color: #68174a !important;
    text-transform: capitalize !important;
    border: 1px solid #68174a !important;
    font-family: 'sf_ui_displayregular';
}

.nb-btn-b {
    justify-content: center !important;
}

.nb-p-para {
    text-align: center;
    margin: 10px;
    font-family: 'sf_ui_displayregular';
    padding-top: 20px;
}

.nb-job-main {
    background-color: #fde8eb;
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 5px;
    margin-top: 50px;
    /* box-shadow: 1px 1px 15px black; */
}

/* .nb-job-list{
    display: flex;
} */

.nb-head-heading {
    color: #ef4625;
    font-weight: bold;
    font-size: 1rem;
    font-family: 'new_york_mediumbold';
    margin-bottom: -5px;
    margin-top: -20px;
}
.nb-head-heading_line{
    margin-top: 20px;
}

.nb-head-para {
    font-weight: bold;
    font-family: 'sf_ui_displayregular';
}

.nb-pa p {
    font-weight: bold;
    font-family: 'sf_ui_displayregular';
}

.nb-para-h5 {
    font-family: 'new_york_mediumbold';
    color: #ef4625;
}

.all-nb-job {
    /* display: flex; */
    /* justify-content: space-around; */
    flex-wrap: wrap;

}

.nb-job-flex-h5 {
    display: flex;
    width: 18%;
    text-align: center;
    padding: .7rem;
    /* margin-bottom: 2em; */
    font-family: 'sf_ui_displayregular';
}

.ver-line-nb {
    border: 2px solid black;
    height: 2rem;
}

.nb-hr-job {
    border: 2px solid #68174a;
    margin-right: 1.5rem;
}

.Next-Round-flex {
    display: flex;
    /* justify-content: space-between; */
    text-align: justify;

}

.Next-Round {
    width: 90%;
    padding-right: 1rem;
    text-align: justify;
}

.Next-Round h5 {
    font-family: 'new_york_mediumbold';
    color: #ef4625;
}

.Next-Round p {
    font-family: 'sf_ui_displayregular';
}

.Any-additional {
    width: 50%;
    padding-left: 1rem;
}

.Any-additional h5 {
    font-family: 'new_york_mediumbold';
    color: #ef4625;
}

.Any-additional p {
    font-family: 'sf_ui_displayregular';
}

.nb-icn img {
    width: 100px;
}


/*git*/


.git-flex2-img111111-nb {
    position: absolute;
    top: 2820px;
    right: 11rem;
    z-index: 2;
}

.git-flex2-img222222-nb {
    position: absolute;
    top: 2955px;
    right: 5rem;
    z-index: 0;
}



/* media query */



@media screen and (max-width:991px) {

    .all-nb-job {
        flex-direction: column;
    }

    .nb-job-flex-h5 {
        width: 100%;
    }

    .Next-Round-flex {
        flex-direction: column;
    }

    .Next-Round {
        width: 100%;
    }

    .Any-additional {
        width: 100%;
        padding-left: 0;
    }

    .git-flex1 {
        width: 100%;
    }

    .name {
        width: 100%;
    }

    .git-flex1 textarea {
        width: 100%;
    }

    .git-btn-flex button {
        margin: 5px 0;
    }

}

@media screen and (max-width:767px) {
    .nb-btn-b {
        display: flex !important;
        flex-direction: column !important;
    }

    .btn-comp-nbn-1 {
        margin-top: 1rem !important;
        margin-left: unset !important;
    }
}