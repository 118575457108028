/* #ef4625

#68174a

#fbcccc

#fde8eb


*/


@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


.Free-Today {
    background-color: #fde8eb;
    /* text-align: center; */
    padding: 3rem 5rem;
}

.cen-start-cen {
    text-align: center;
}

.pop-icn-fill {
    color: gray;

}

.input {
    border: 1px solid rgb(209, 209, 209) !important;
    /* color: rgb(209, 209, 209) !important; */
    height: 40px;
    /* box-shadow: 0px 0px 30px rgba(0, 0, 0, ); */
    /* width: 60% !important; */
}

.reg-to {
    font-family: 'new_york_mediumbold';
}

.reg-start {
    font-family: 'new_york_mediumbold';
    color: #68174a;
    margin: 1rem;
}

.check-label{
    width: 100%;
}

.reg-start span {
    color: #ef4625;
}

.reg-para {
    font-family: 'sf_ui_displayregular';
    margin-bottom: 1rem;
}

.r-form-first {
    background-color: white;
    padding: 3rem;
    /* margin: 3rem 5rem 0 5rem; */
    border-radius: 15px;

}

.r-form-first-flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Upload__file {
    width: 100%;
    color: #ef4625;
}

.Upload__file input {
    width: 100% !important;
}

.upload__input {
    width: 100% !important;
}

.flex-left {
    width: 50%;
    /* background-color: aqua; */
}

.flex-right {
    width: 50%;
    /* background-color: brown; */
}

.f-l-name {
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.f-name {
    width: 47%;
}

.f-name label {
    /* width: 50%; */
    float: left;
    color: #68174a;
    font-weight: bold;
}

.f-name input {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
    float: left;
}

.l-name {
    width: 47%;
}

.l-name label {
    /* width: 50%; */
    float: left;
    color: #68174a;
    font-weight: bold;
}

.l-name input {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
}



.r-f-pass label {
    float: left;
    color: #68174a;
    font-weight: bold;
}

.r-f-pass input {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 95%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
    float: left;
}


.r-f-email label {
    float: left;
    color: #68174a;
    font-weight: bold;
}

.r-f-email input {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 95%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
    float: left;
}

.r-f-school label {
    float: left;
    color: #68174a;
    font-weight: bold;
}


.r-f-school input {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 95%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
    float: left;
}

.r-f-school select {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 95%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
    float: left;
}



.phone label {
    float: left;
    margin-left: 5%;
    color: #68174a;
    font-weight: bold;
}

.phone input {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 2rem;
    float: right;
    width: 95%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
}

.re-pass label {
    float: left;
    margin-left: 5%;
    color: #68174a;
    font-weight: bold;
}

.re-pass input {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 2rem;
    float: right;
    width: 95%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
}


.dob-gen {
    display: flex;
    justify-content: space-between;
    width: 100%;
    float: right;
}

.dob-genn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    float: right;
}

.dob {
    width: 47%;
    margin-left: 3%;
}

.dob label {
    float: left;
    margin-left: 5%;
    color: #68174a;
    font-weight: bold;
}

.dob input {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 2rem;
    float: right;
    width: 95%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
}

.gen {
    width: 47%;
}

.gen label {
    float: left;
    margin-left: 5%;
    color: #68174a;
    font-weight: bold;
}

.genn label {
    float: left;
    margin-left: 5%;
    color: #68174a;
    font-weight: bold;
}

.gen select {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 2rem;
    float: right;
    width: 95%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */

}

.gen input {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 2rem;
    float: right;
    width: 95%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */

}

.genn input {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 2rem;
    float: right;
    width: 90%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */

}

.tick-mark-reg {
    color: #68174a !important;
    font-weight: bold !important;
}

.r-f-school-m label {
    float: left;
    margin-left: 5%;
    color: #68174a;
    font-weight: bold;

}

.r-f-school-m input {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 95%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
    float: right;

}

.r-f-school-m select {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 95%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
    float: right;

}

.Or-continue-with {
    margin: 1rem;
    color: gray;
    text-align: center;
}

.btn-comp-nb-r {
    width: 100% !important;
    padding: .6rem !important;
    background-color: white !important;
    color: #ef4625 !important;
    border: 1px solid gray !important;
    text-transform: capitalize !important;
    font-weight: bold !important;
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
}

.ml-fp-r {
    display: flex;
    justify-content: space-between;
}

.r-btn-next {
    font-family: 'new_york_mediumbold';
}

.r-btn-next button {
    font-size: 1.1rem !important;
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: white;
    /* margin-right: 5rem;
    margin-left: 1.5em; */
    width: 100%;
    margin-top: 2rem;
}

.reg1-sign p {
    margin: 1rem 0 0 0;
    color: gray;
    text-align: center;
}

.reg1-sign span {
    color: #ef4625;
    border-bottom: 1px solid #ef4625;
}

.med-gg {
    align-items: center;
    float: left;
}


/* media query */

@media screen and (max-width:991px) {
    .r-form-first {
        margin: 3rem 0 0 0;
    }
}


@media screen and (max-width:768px) {
    .f-l-name {
        flex-direction: column;
        width: 100%;
    }

    .f-name {
        width: 100%;
    }

    .l-name {
        width: 100%;
    }

    .r-f-pass input {
        width: 100%;
    }

    .r-f-email input {
        width: 100%;
    }

    .r-f-school select {
        width: 100%;
    }

    .dob-gen {
        flex-direction: column;
    }



    .dob {
        width: 100%;
        margin-left: 0;
    }

    .gen {
        width: 100%;
    }

    .genn {
        width: 100%;
    }
}


@media screen and (max-width:576px) {
    .r-form-first-flex {
        flex-direction: column;
    }

    .flex-left {
        width: 100%;
    }

    .flex-right {
        width: 100%;
    }

    .phone label {
        margin-left: 0;
    }

    .dob-genn {
        flex-direction: column;
    }

    .phone input {
        width: 100%;
    }

    .dob label {
        margin-left: 0;
    }

    .dob input {
        width: 100%;
    }

    .gen label {
        margin-left: 0;
    }

    .gen select {
        width: 100%;
    }

    .genn input {
        width: 100%;
    }

    .genn label {
        margin-left: 0;
    }

    .r-f-school-m label {
        margin-left: 0;
    }

    .r-f-school-m input {
        width: 100%;
    }

    .r-f-school-m select {
        width: 100%;
    }
    .r-btn-next button{
        font-size: 0.9rem !important;
        margin-top: 1rem;
    }
}