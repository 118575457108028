/* #ef4625

#68174a

#fbcccc

#fde8eb


*/

@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.Updates {
    padding: 0.5rem;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
    background-color: rgb(239, 70, 37);
}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


.ce-ex-re {
    text-align: center;
}


.expe {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.company-name {
    width: 50%;
}

.company-namee {
    width: 50%;
}

.company-name select {
    width: 97.55%;
    padding: .6rem !important;

    color: gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
}

.company-name input {
    width: 97.55%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    /* border: 1px solid gray !important; */
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
}

.company-name label {
    float: left;
    /* padding-left: 2.5%; */
    margin-bottom: 1rem;

    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.company-namee input {
    width: 47.5%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    /* border: 1px solid gray !important; */
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
    margin-right: 0.4rem;
}

.company-namee select {
    width: 47.5%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
}

.left-year-month {
    margin-left: 2.5%;
}

.company-namee label {
    float: left;
    /* padding-left: 2.5%; */
    margin-bottom: 1rem;

    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.job-title {
    width: 50%;
}

.job-titlee {
    width: 50%;
}

.job-title select {
    width: 97.55%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    /* border: 1px solid rgb(209, 209, 209) !important; */
    /* text-transform: capitalize !important;
    font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.bbbb-h-bor {
    border: 1px solid rgb(209, 209, 209) !important;
}

.job-titlee select {
    width: 47.5%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    /* border: 1px solid gray !important; */
    /* text-transform: capitalize !important;
    font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.ptt {
    padding-top: 1rem;
}


.job-title input {
    width: 97.55%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    /* border: 1px solid gray !important; */
    /* text-transform: capitalize !important;
    font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.job-titlee input {
    width: 47.5%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    /* border: 1px solid gray !important; */
    /* text-transform: capitalize !important;
    font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: right; */
    border-radius: 5px;
    margin-left: 2.5%;
    margin-bottom: 1rem;
}

.job-title label {
    float: left;
    padding-left: 2.5%;
    margin-bottom: 1rem;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.job-titlee label {
    float: left;
    padding-left: 2.5%;
    margin-bottom: 1rem;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.rnr {
    width: 100%;
}

.rnr input {
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important;
    font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
}

.rnr-flex label {
    float: left;
    /* margin: 1rem 0; */
    margin-top: 1rem;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.rnr-flex button {
    border: none;
    background: none;
    font-family: 'new_york_mediumbold';
    color: #ef4625;
    margin-top: 1rem;
}

.rnr-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
}

.red-span {
    color: #ef4625;
}

.date1 {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.Started {
    width: 50%;
}

.Started input {
    width: 46.5%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
}

.Started select {
    width: 46.5%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: left; */
    border-radius: 5px;
    margin-left: 2%;
}

.Started label {
    float: left;
    /* margin: 1rem 0; */
    margin-bottom: 1rem;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.Current {
    width: 50%;
}

.Currentt {
    width: 50%;
}

.Current input {
    width: 95%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
}

.Currentt input {
    width: 46.5%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
}

.hide-cy {
    pointer-events: none;
    opacity: 0.5;
}

.Currentt select {
    width: 46.5%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: left; */
    border-radius: 5px;
    margin-left: 2%;
}

.Current label {
    float: left;
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.Currentt label {
    float: left;
    /* margin: 1rem 0; */
    margin-bottom: 1rem;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.date2 {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.Left {
    width: 50%;
}

.Left input {
    width: 95%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.Left select {
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid rgb(209, 209, 209) !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: right; */
    border-radius: 5px;
}

.Left label {
    float: left;
    /* padding-left: 5%; */
    /* margin: 1rem 0; */
    margin-bottom: 1rem;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.Duration {
    width: 50%;
}

.Durationn {
    width: 50%;
}

.Duration select {
    width: 95%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid rgb(209, 209, 209) !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.please {
    color: #ef4625;
    margin-left: 10px;
}

.hoho {
    margin-right: unset !important;
}

.r-form-first {
    padding: 25px 50px;
}

.basic-multi-select {
    width: 95%;
    /* padding: .6rem !important; */
    background-color: white !important;
    color: gray !important;
    border: 1px solid rgb(209, 209, 209) !important;
    outline: none !important;
    float: right;
    border-radius: 5px;
    font-size: 15px !important;
}

div[disabled] {
    pointer-events: none;
    opacity: 0.7;
}

.Duration input {
    width: 95%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.Duration label {
    float: left;
    padding-left: 5%;
    /* margin: 1rem 0; */
    margin-bottom: 1rem;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.Durationn label {
    float: left;
    padding-left: 5%;
    /* margin: 1rem 0; */
    margin-bottom: 1rem;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}


.j-titlee label {
    float: left;
    /* padding-left: 5%; */
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.flex-ad-mo {
    display: flex;
    justify-content: space-between;
}

.j-titlee input {
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}


.org-lo label {
    float: left;
    /* padding-left: 5%; */
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.org-lo input {
    width: 100%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    /* border: 1px solid gray !important; */
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.org-lo select {
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.loc-loc-1px {
    border: 1px solid rgb(209, 209, 209) !important;
}

.j-titlee textarea {
    width: 100%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    border: 1px solid rgb(209, 209, 209);
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: right; */
    border-radius: 5px;
}


.ml-fp-rr {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.btn-labeli {
    background: none;
    border: none;
    color: #ef4625;
    font-family: 'new_york_mediumbold';
    float: right;
}


.cat-emp-reg select {
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: right; */
    border-radius: 5px;
}



/* media query */

@media screen and (max-width:992px) {

    .company-namee label {
        height: unset !important;
    }

    .job-titlee label {
        height: unset !important;
    }

    .expe {
        flex-direction: column;
    }

    .company-name {
        width: 100%;
    }

    .company-namee {
        width: 100%;
    }

    .company-namee input {
        width: 100%;
        margin-right: unset !important;
    }

    .hoho {
        margin-top: 0.5rem;
    }

    .job-titlee {
        width: 100%;
    }

    .job-titlee label {
        padding-left: unset;
    }

    .job-titlee label {
        width: 100%;
    }

    .job-titlee input {
        margin-left: unset;
        width: 100%;
        margin-bottom: 0;
    }

    .job-title {
        width: 100%;
        margin-top: 0.5rem;
    }

    .company-name select {
        width: 100%;
    }

    .company-name input {
        width: 100%;
    }

    .job-title select {
        width: 100%;
    }

    .job-title label {
        padding-left: 0;

    }

    .cu-mar {
        margin-top: 1rem;
    }

    .job-title input {
        width: 100%;
    }

    .date {
        flex-direction: column;
    }

    .date1 {
        flex-direction: column;
        width: 100%;
    }

    .Started {
        width: 100%;
    }

    .Started input {
        width: 100%;
    }

    .Current {
        width: 100%;
    }

    .Current input {
        width: 100%;
    }

    .date2 {
        flex-direction: column;
        width: 100%;
    }

    .Left {
        width: 100%;
    }

    .Left label {
        padding-left: 0;
    }

    .Left input {
        width: 100%;
    }

    .Duration {
        width: 100%;
    }

    .Durationn {
        width: 100%;
        margin-top: 0.5rem;
    }

    .Durationn label {
        float: left;
        padding-left: unset;
        /* margin-top: 0.5rem; */
    }

    .please {
        color: #ef4625;
        margin-left: unset;
    }

    .basic-multi-select {
        width: 100%;
    }

    .Duration label {
        padding-left: 0;
    }

    .Duration select {
        width: 100%;
    }
}


@media screen and (max-width:1250px) {
    .company-namee label {
        height: 60px;
    }


}

@media screen and (max-width:600px) {}

@media screen and (max-width:768px) {
    .p-btn {
        margin-top: 0;
        margin-bottom: 3rem;
    }

    .p-img img {
        width: unset;
    }
}