/* #ef4625

#68174a

#fbcccc

#fde8eb


*/

@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.skew {
    transform: skew(0deg, -5deg);
}

.We-Are-Here-To-Help {
    transform: skew(0deg, 5deg);
}

/* Contact-Us */
.Contact-Us {
    background-color: #fbcccc !important;
}
.cont{
    color: #68174a !important;
}
.Contact-Us
{
    padding: 3rem 5rem !important;
}
.Contact-Us .cont {
    text-align: center;
    color: #ef4625;
    background-color: #fbcccc;
    padding: 3rem 5rem ;
    font-family: 'new_york_mediumbold';
}

/* We Are Here To Help */

.wah-head {
    padding: 5rem 5rem;
    /* padding: 0px !important; */
    background-color: #68174a;
}

.We-Are-Here-To-Help {
    display: flex;
    justify-content: space-between;

}

.wahth-img {
    width: 50%;
    padding-right: 2rem;
}

.wahth-img img {
    width: 100%;
    border-radius: 25px;
}

.wahth-text {
    width: 50%;
    padding-left: 2rem;
}

.wahth-text-heading {
    color: #fbcccc;
}

.wahth-text-para {
    color: #fbcccc;
}

.wahth-btn {
    display: flex;
}

.wahth-btn-search {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: #fde8eb;
    margin-right: 2rem;
}

.wahth-btn-learn {
    border: none;
    background: none;
    border-bottom: .1em double #ef4625;
    color: #ef4625;
}


/*git*/


.git-flex2-img1111 {
    position: absolute;
    top: 5rem;
    left: 0rem;
    z-index: 2;
}

.git-flex2-img2222 {
    position: absolute;
    top: 13rem;
    left: 5rem;
    z-index: 0;
}


