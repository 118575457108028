




.cen-start-cen{
    text-align: center;
}


.reg2{
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.school{
    width: 50%;
}

.space-zero{
    padding: 100px 200px;
}

.school select{
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
}

.school input{
    width: 100%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    /* border: 1px solid gray !important; */
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
}

.school label{
    float: left;
    margin-bottom: 1rem;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}
.pro{
    width: 50%;
}

.pro select{
    width: 97.5%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.pro input{
    width: 97.5%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    /* border: 1px solid gray !important; */
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.pro label{
    float: left;
    padding-left: 2.5%;
    margin-bottom: 1rem;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.reg3{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.reg3-1{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.in-year{
    width: 50%;
}

.in-year input{
    width: 95%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
}

.in-year label{
    float: left;
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}
.out-year{
    width: 50%;
}

.out-year input{
    width: 95%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
}

.out-year label{
    float: left;
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.reg3-2{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.marks{
    width: 50%;
}

.marks input{
    width: 95%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.marks label{
    float: left;
    padding-left: 5%;
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.token-education{
    width: 100%;
}

.token-education label{
    float: left;
    /* padding-left: 5%; */
    margin: 0 0 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.token-education input{
     width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
   
    outline: none !important;
   
    /* float: right; */
    border-radius: 5px;
}
.grade{
    width: 50%;
}

.grade select{
    width: 95%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.grade label{
    float: left;
    padding-left: 5%;
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}



.t-itle label{
    /* width: 100%; */
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.t-itle input{
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}


.sta-t label{
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.sta-t input{
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.e-nd label{
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.e-nd input{
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.textaria-edu label{
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.textaria-edu textarea{
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}


.post-gr-col-font{
    font-family: 'new_york_mediumbold';
    color: #ef4625;
    
}

.post-gr-col-fontt{
    font-family: 'new_york_mediumbold';
    color: white;
    border: 1px solid  #68174a;
    background-color: #68174a;
    padding: 0.5rem;
    border-radius: 5px;
    
    
}

.post-gr-col-fontt:hover{
    color: white;
    border: 1px solid white;
    background-color: #ef4625;
    transition: 1s;
}

.flex-h-ad-m{
    display: flex;
    justify-content: space-between;
}

.add-m-ore-gold{
    font-family: 'new_york_mediumbold';
    color: white;
    float: right;
    /* margin-top: 1rem; */
    border: 1px solid  #68174a;
    background-color: #68174a;
    padding: 0.5rem;
    border-radius: 5px;
}

.add-m-ore-gold:hover{
    color: white;
    border: 1px solid white;
    background-color: #ef4625;
    transition: 1s;
}

.pass-out-year-h{
    width: 100%;
}

.pass-out-year-h-mm{
    width: 100%;
}

.pass-out-year-h input{
    width: 100%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    /* border: 1px solid gray !important; */
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: left;
    border-radius: 5px;
}

.pass-out-year-h-mm input{
    width: 97.55%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    /* border: 1px solid gray !important; */
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.pass-out-year-h label{
    float: left;
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.pass-out-year-h-mm label{
    float: left;
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
    margin-left: .7rem;
}

.pass-out-year-h select{
    width: 97.55%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    float: right;
    border-radius: 5px;
}

.lll{
    margin-left: .5rem !important;
}

.condidate-id{
    width: 100%;
}

.condidate-id label{
    float: left;
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.condidate-id input{
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: right; */
    border-radius: 5px;
    margin-bottom: 1rem;
}


.gr-fl-bt{
    margin-bottom: 1rem;
    /* border: 1px solid gray !important;
    background-color: white; */
}

.edit_formm{
    padding: .5rem;
    border: 1px solid  #68174a;
    background-color: #68174a;
    border-radius: 5px;
    color:  white;
    /* margin-left: 1rem; */
    margin: 0 0.7rem 1rem 0;
    font-weight: bold;
    cursor: pointer;
}

.edit_formm:hover{
    color: white;
    border: 1px solid #ef4625;
    background-color: #ef4625;
}

.note-res{
    margin-top: 0.3rem;
    color: #ef4625;
    margin-bottom: 3rem
}

.update-c{
    display: flex;
    justify-content: space-between;
}
.update-c-pra{
    display: flex;
    gap: 20px;
}
/* .style {
    background-color: rgb(239, 70, 37);
} */
.Updates{
    padding: 0.5rem;
    border-radius: 5px;
    border:none;
    color: white;
    cursor: pointer;
    background-color: rgb(239, 70, 37);
    /* margin-left: -10px !important; */
}



.update-c span{
    justify-content: space-around !important;
    align-self: center;
}


/* media query */
@media screen and (max-width:415px){
    .text-btn-button{
        width: 100% !important;
        padding:1px;
        font-size: 10px !important;
    }
    .note-res h5{
        font-size: 15px !important;
        /* margin-top: 3rem !important; */
    }
}


@media screen and (max-width:770px){
    .text-btn-button{
        width: 100% !important;
        padding:2px;
        font-size: 12px !important;
    }
    .Updates{
        margin-left: 0px;
        /* font-size: 10px; */
    }
}


@media screen and (max-width:576px) {
    .Updates{
        margin-left: 0px;
    }
    .Update-c{
        display: flex;
    }
    .update-c span{
        justify-content: space-between !important;
        width: 100px;
        align-self: center;
        /* font-size: 15px; */
    }
    
    
    .reg2 {
        flex-direction: column;
        width: 100%;
    }

    .school {
        width: 100%;
    }

    .school select{
        width: 100%;
    }

    .pro {
        width: 100%;
    }

    .pro select{
        width: 100%;
    }

    .pro label {
        float: left;
        padding-left: 0;
        margin-top: 1rem;
    }

    .reg3{
        flex-direction: column;
    }

    .pass-out-year-h input{
        width: 100%;
    }

    .pass-out-year-h-mm input {
        width: 100%;
    }

    .gr-fl-bt {
        margin-bottom: 0;
    }
}

@media screen and (max-width:1200px) {
    .space-zero{
        padding: 0;
    }
}

@media screen and (max-width:992px) {
    .pass-out-year-h-mm label {
        height: 35px;
    }

    .pass-out-year-h label {
        height: 35px;
    }
}

@media screen and (max-width:768px) {
    .pro label {
        height: 35px;
    }

    .school label{
        height: 35px;
    }
}

@media screen and (max-width:700px) {
    .flex-h-ad-m{
        flex-direction: column;
    }

    .add-m-ore-gold {
        text-align: center;
    }

    .reg2{
        flex-direction: column;
    }

    .pro {
        width: 100%;
    }

    .school {
        width: 100%;
    }

    .pro label {
        height: unset;
        margin-top: .5rem;
        padding-left: unset;
    }

    .pro input {
        width: 100%;
    }

    .pass-out-year-h-mm input {
        width: 100%;
    }

    .school label{
        height: unset;
    }

    .reg3{
        flex-direction: column;
    }

    .pass-out-year-h label {
        height: unset;
    }

    .pass-out-year-h-mm label {
        height: unset;
        margin-left: unset;
    }
}
