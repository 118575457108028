.Employers {
    padding: 3rem 5rem 5rem 5rem;
    background-color: #fde8eb;
}

.f-namer {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */

}

.emp-hed {
    /* margin: 8rem 0 0 5rem; */
    margin: 8rem 0 0 0rem;
    /* border: 1px solid red; */
}

.emp-hed h2 {
    color: #ef4625;
    letter-spacing: 4px;
}

.emp-hed p {
    color: gray;
    word-spacing: 2px;
}

.f-namer input {
    padding: 0.4rem;
    margin: 0 0 0.5rem 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(214, 207, 207);
    outline: none;
    font-size: 12px;
    /* box-shadow: 0px 0px 2px 1px grey; */
    /* float: left; */
}

.l-n-emp-m {
    /* padding-left: 6.5px; */
    color: #68174a;
    margin-top: 0.3rem;
    font-weight: bold;
    font-size: 12px;
}

/* .wdt input{
 border: 2px solid gold;
 width: 100% !important;
} */

.col-emp-j {
    color: #68174a;
    margin-top: 0.3rem;
    font-weight: bold;
    font-size: 12px;

}

/* .element.style input{
    padding: 0.4rem;
    margin: 0 0 0.5rem 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(214, 207, 207);
    outline: none;
  
    float: left;
    font-size: 12px;
} */
.pos-rel {
    position: relative;
    /* border: 1px solid red; */

}

.lispacing {
    margin-top: 15px;
}

.mar-b-mar {
    margin-bottom: 5rem;
    /* background-color: red; */
    /* width: 100% !important; */
}

.clo-p-col-j {
    background-color: #fde8eb;
}

.emp-form {
    background-color: white;
    padding: 2rem;
    border: 1px solid white;
    border-radius: 10px;
    width: 90%;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 20%);
    margin: 0 0 0 2.5rem;
    /* margin: 0 0 0 5rem; */
    position: absolute;
    z-index: 5;
    padding: 10px;
}

.emp-form h3 {
    color: #ef4625;
    text-align: center;
}


.j-title input {
    padding: 0.4rem;
    margin: 0 0 0.5rem 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(214, 207, 207);
    outline: none;
    /* box-shadow: 0px 0px 2px 1px grey; */
    float: left;
    font-size: 12px;
}

.emp-phone input {
    padding: 0.4rem;
    margin: 0 0 0.5rem 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(214, 207, 207);
    outline: none;
    /* box-shadow: 0px 0px 2px 1px grey; */
    float: left;
    font-size: 12px;
}

.emp-email input {
    padding: 0.4rem;
    margin: 0 0 0.5rem 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(214, 207, 207);
    outline: none;
    /* box-shadow: 0px 0px 2px 1px grey; */
    float: left;
    font-size: 12px;
}

.emp-s-m {
    padding: 10px 25px;
    margin-top: 16px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: white;
    /* margin-right: 5rem; */
    /* margin-left: 1.5em; */
    font-family: 'new_york_mediumbold';
    width: 100%;
    /* border: 3px solid green; */
}

.emp-s-m:hover {
    background-color: #1976d2;
}


.hhh {
    /* border: 1px solid red; */
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.l-n-emp {
    float: right;
}

.p-img {
    padding: 4rem 0rem 0rem 1.5rem;
    /* border: 1px solid red; */


}

.Hire-Easily {
    /* padding: 9rem 5rem 5rem 5rem; */
    padding: 9rem 0rem;
    background-color: #68174a;
    transform: skew(0deg, -5deg);

}

/* .pro-pad{
    padding: 9rem 0rem;
} */




.h-e-easily {
    transform: skew(0deg, 5deg);
    text-align: justify;

}

.h-e-easily p span {
    /* margin-left: -20px; */

    text-align: justify !important;
}

.h-e-easily p {
    display: flex;
    justify-content: center;
    margin-top: 15px !important;
}

.h-e-easily p label {
    /* border: 1px solid red; */
    padding-left: 20px;
}

/* .h-e-easily span{
    padding-left: -60px;
} */
/* .spn{
    color: white;
    text-align: justify;
} */


.emp-immg {
    width: 100%;
    /* padding-right: 2rem; */
    border-radius: 25px;
}
.success{
    padding:15px;
}
.error{
    color: red;
}
.emp-search-hire {
    color: #fde8eb;
    /* border: 1px solid red; */
    /* padding: 10px; */
    
}

.emp-use-para {
    color: #fde8eb;
    text-align: justify;
}

.emp-immg {
    height: 400px;
}

/* .ali-cen .emp-search-hire{
    margin-top: -40px;
} */
.emp-btn-learn-more {
    border: none;
    background: none;
    border-bottom: .1em double #ef4625;
    color: #ef4625;
}

.p-btn {
    border: 1px solid red;
    margin-left: 30px;
}


.p-textt h2 span {
    color: white !important;

}

.p-textt h2 {
    display: flex;
    justify-content: center;
    padding: 0px 22px;
}

.p-textt {
    width: 60% !important;
}

.p-textt {
    /* border: 1px solid red; */
    padding: 0rem 2rem;
}


.ali-cen {
    /* margin: 4.5rem 0 0 0 !important; */
    margin: 0rem 0 0 0 !important;
}

.Why-Recruiters {
    padding: 4rem 0rem 5rem 0rem;
    background-color: #fde8eb;


}

.pro-pad {
    /* border: 1px solid red; */
    padding: 3rem 5px !important;
}

.why-land {
    color: #ef4625;

}

.when-land {
    color: gray;
}

.wr-con {
    text-align: center;
}


.customerr {
    /* margin: 0 25px; */
    margin: 0px 5px;
}

.customerr blockquote {
    display: flex;
    align-items: center;
    border-radius: 8px;
    position: relative;
    /* background-color: #fbcccc; */
    /* background-color: #efc2ca; */
    background-color: #f3cbd2;

    /* background-color: rgb(255, 214, 221); */
    /* background-color: #F2CEF1; */
    color: gray;
    padding: 25px 50px 30px 50px;
    font-size: 0.8em;
    font-weight: 500;
    margin: 0;
    line-height: 1.6em;
    font-family: 'sf_ui_displayregular';
    height: 12rem;
    /* font-style: italic; */
}



.arrow-rot {
    transform: rotate(90deg);
    margin-left: 15px;
    margin-top: -14px;
    /* color: #fbcccc; */
    /* color: rgb(255, 214, 221); */
    color: #efc2ca;
}

.cus-img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin-right: 10px;
}

.author h5 {
    font-family: 'new_york_mediumbold';
    color: #ef4625;
}

.author span {
    font-size: .6rem;
    color: gray;
}

.author-flex {
    display: flex;
}

.ohc-load-more {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: #fde8eb;
    margin-right: 2rem;
}

.btn-ohc {
    margin-top: 3rem;
    text-align: center;
}


.land-flex-emp {
    display: flex;
    justify-content: space-between;
}

.land-flex-emp img {
    width: 100px;
}

.Trusted-Partners {
    padding: 5rem;
    background-color: white;
    text-align: center;
}

.emp-land-trust {
    color: #ef4625;
    margin-bottom: 1rem;
}

.jut {
    background-color: gray;
    margin-top: 1rem;
    display: none;
}



/* media query */



@media screen and (max-width:991px) {

    .p-textt {
        width: 100% !important;
    }

    .Problems {

        flex-direction: column-reverse !important;
    }

    .p-img {
        padding: 20px 50px !important;


    }


    .emp-form {

        width: 100%;
        /* box-shadow: 0px 0px 2px 1px grey;  */
        margin: 0;
        /* border: 1px solid red; */
    }

    .f-namer {
        flex-direction: column;
    }

    .f-namer input {
        width: 100%;
    }

    .customerr blockquote {
        height: unset;
    }

    .land-flex-emp {
        display: none;
    }

    .jut {
        display: block;
    }

}

@media screen and (max-width:576px) {
    .emp-hed {
        margin: 0;
    }
}

@media screen and (max-width:768px) {
    .emp-form {
        position: unset;
    }

    .emp-hed {
        margin: unset;
        text-align: center;
    }

    .Employers {
        padding: 3rem 5rem 0rem 5rem;
    }

    .Hire-Easily {
        padding: 5rem 5rem 1rem 5rem;
    }

    .p-textt {
        width: 100%;
        text-align: justify;



    }

    .pro-pad {
        padding: 1rem 0rem 5rem 0rem;
    }
}