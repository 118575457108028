



/* #ef4625

#68174a

#fbcccc

#fde8eb


*/

@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
         url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
         url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


.Member-Login{
    background-color: #fde8eb;
    text-align: center;
    padding: 3rem 5rem;
}

.ml-fp{
    display: flex;
    justify-content: center;
}

.signUp{
    display: flex;
    justify-content: center;
}

.ml-wel{
    color: #ef4625;
    font-family: 'new_york_mediumbold';
}

.ml-mem{
    font-family: 'new_york_mediumbold';
}

.ml-mem span{
    color: #ef4625;
}

.ml-access{
    font-family: 'sf_ui_displayregular';
}

.eu-mll{
    font-family: 'new_york_mediumbold';
    margin-left: -154px;
}


.pass-mll{
    font-family: 'new_york_mediumbold';
    margin-left: -165px;
}

.con-ml{
    font-family: 'sf_ui_displayregular';
}

.input-userr{
    width: 20rem;
    padding: 1rem ;
    border: 1px solid gray;
    border-radius: 5px;
}

.input-passs{
    width: 20rem;
    padding: 1rem;
    border: 1px solid gray;
    border-radius: 5px;
}

.btn-comp-nb{
    width: 20rem !important;
    padding: .6rem !important;
    background-color: white !important;
    color: #68174a !important;
    border: 1px solid gray !important;
}


.check-label{
    margin-right: 4.3rem !important;
}


.btn-label{
    background: none;
    border: none;
    color: #ef4625;
    font-family: 'new_york_mediumbold';
}

.validate{
    width: 20rem;
    padding: 1rem 0;
    background-color: #ef4625;
    border: 1px solid #ef4625;
    border-radius: 5px;
    font-family: 'new_york_mediumbold';
    color: white;
}

.p-signUp{
    font-size: .8rem;
    color: gray;
    margin-right: 1rem;
}

.btn-sign{
    border: none;
    background: none;
    font-family: 'sf_ui_displayregular';
    font-weight: bold;
    border-bottom: 1px solid #68174a;
    color: #68174a;
}

.link-icnn{
    color: rgb(24, 24, 221);
    font-size: 2rem !important;
}



@media screen and (max-width:414px){
    .eu-mll {
        
        margin-left: -105px;
    }

    .pass-mll {
        
        margin-left: -115px;
    }
}

@media screen and (max-width:480px){
    .eu-mll {
        
        margin-left: -105px;
    }

    .pass-mll {
        
        margin-left: -115px;
    }
}