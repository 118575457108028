.Available-Now {
    padding: 5rem;
    padding: 2rem 5rem;
    background-color: #fde8eb;
    text-align: center;
}


.Available-Now .clr {
    color: #ef4625;
}

.search-bar-hh {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    background-color: white;
    padding: 10px 10px;
    border-radius: 10px;
    /* box-shadow: 5px 5px 5px #aaaaaa; */
    width: 66%;
}

.pagination {
    display: flex;
    justify-content:space-around;
    align-items: center;
    margin-left: 40%;
    /* width: 100%; */
    /* border: 1px solid black */
    width: 15%;

}

.pagination button {
    padding: 7px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* outline: transparent; */
    border: none;
    border-radius: 5px;
    background-color: transparent;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, .2);
}

.pagination span {
    font-size: 16px;
    font-family: 'sf_ui_displayregular'
}

.sortBy {
    color: black !important;
}

.reset-btn {
    padding:3px 7px;
    background: none;
    /* color: white; */
    color: red;
    outline: none;
    border: 1px solid black;
    border-radius: 5px;

    /* font-weight: bold; */
    /* box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2); */
    /* background-color: #ef4625; */

}

.reset-btn:hover {
    /* background-color: #68174a; */
    color: white;
    transition: 200ms;
    /* border-color: transparent; */
}

.pagination button:hover {
    background-color: #68174a;
    color: white;
    transition: 200ms;
    border-color: transparent;

}
.jb-ser-loca option{
    background-color: #fbcccc;
}
.crl{
    border-radius: 50% !important;
    color: white;
}

.employmentType {
    display: flex;
    justify-content: center;
}

.search-bar-hh button {
    background-color: #ef4625;
    color: #fde8eb;
    border-radius: 8px;
    border: none;
    text-transform: capitalize;
    font-family: 'new_york_mediumbold';
}

.search-bar-hh input {
    border: none;
    width: 6rem;
}

.no-outline:focus {
    outline: none;
}

.btn-serch-icn {
    margin-top: 7px;
}

.ali-job-cen {
    display: flex;
    justify-content: center;
}


/* Advance Filter */

.Advance-Filter {
    padding: 5rem;
    background-color: #fbcccc;
    transform: skew(0deg, -5deg);
}

.skw {
    transform: skew(0deg, 5deg);

}

.col-back-col {
    background-color: #fde8eb;
}

.flex-ad-fil {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.flex-ad-fil h6 {
    color: #ef4625 !important;
}

.loc-loc {
    color: #ef4625;
}

.jb-se-loc {
    border: 1px solid #68174a;
    padding: .5rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.jb-se-loc input {
    background-color: #fbcccc;
}

.jb-ser-loca {
    border: none;
    outline: none;
}

.btn-ser-btn:hover {
    background-color: #1976d2 !important;

}

#industry-fetc {
    width: 100%;
    border: 1px solid #68174a;
    padding: .5rem;
    border-radius: 5px;
    outline: none;
    background-color: #fbcccc;
}

.Industry h5 {
    color: #ef4625;
    margin-top: 8px;
}

.flexSort {
    display: flex;
    justify-content: flex-end;
}

.Industry select {
    color: gray;
}

.industry-opt {
    margin-top: 1rem;
}

.job-chec-num p {
    border: 1px solid #fde8eb;
    background-color: #fde8eb;
    border-radius: 5px;
    width: 1.2rem;
    margin: 1px 0 0 0;
    padding: .5px;
}

.job-chec-num {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid red; */
}

.Salary-Range {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ef4625;
}

.exp-exp {
    color: #ef4625;
}

.job-jobty {
    color: #ef4625;
}

.col-job-c {
    color: #ef4625 !important;
}

.job-checkbox input {
    width: 100%;
}

/* right */


.det-flex-job {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.marginSmall {
    margin-top: 5px;
}

.fle-short {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.fle-short p {
    margin: 0;
}


.fle-short input {
    padding: .2rem .5rem;
    margin-left: 1rem;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #fbcccc;
    cursor: pointer;
}

.fle-short select {
    padding: .2rem .5rem;
    margin-left: 1rem;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #fbcccc;
    color: gray;
    cursor: pointer;
}

.fle-x-h {
    display: flex;
    /* justify-content: space-; */
}

.highlight {
    /* float: right; */
    font-size: 12px;
    /* border: 1px solid red; */
    padding: .5rem;
    border-radius: 5px;
    margin-left: 1.2rem;
}


.day-card-flexx {
    border: 1px solid white;
    background-color: white;
    width: 40%;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 1em;
    margin-left: 1em;
    max-height: 380px;
    min-height: 350px;
}

.day-card-flexx h5 {
    color: #ef4625;

}

/* 
.pagination-stack-job-search {
    margin-left: 15rem;
} */

/* media query */

@media screen and (max-width:1200px) {
    .search-bar-hh {
        width: 70%;
    }
    
    .Available-Now {
        padding: 2rem 5rem;
    }
    .globalPaddingg{
        padding: 40px 0px 0px 0px !important;
    }
}


@media screen and (max-width:991px) {

    .pagination-stack-job-search {
        margin-left: 0;
    }

    .det-flex-job {
        flex-direction: column;
    }

    .day-card-flexx {
        width: 80%;
    }

    .search-bar-hh {
        width: 100%;

    }

    .jobCardMainConatiner {
        width: 280px;
    }

}

@media screen and (max-width:768px) {
    .search-bar-hh input {
        width: 5rem;
    }

    .jobCardMainConatiner {
        width: 280px;
    }
}


@media (min-width:200px) and (max-width:420px) {

    .jobCardMainConatiner {
        width: 250px !important;
    }
}
@media screen and (max-width: 576px) {
    .search-bar-hh input {
        font-size: 13px;
        width: 3rem;
    }
    .tpmrgn{
        margin-top: 60px !important;
    }

    .search-bar-hh select {
        font-size: 13px;
    }

    .btn-ser-btn {
        font-size: 13px;
    }

    .marginSmalll {
        padding: 0 5rem !important;
    }

    .jobCardMainConatiner {
        width: 300px;
    }


}