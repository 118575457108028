.add-int-li-pro {
    font-family: 'new_york_mediumbold';
    color: white;
    float: right;
    border: 1px solid  #ef4625;
    background-color: #ef4625;
    /* padding: 0.2rem; */
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    margin-top: -2px;
}
/* .add-int-li-pro :visited{
    background-color: #68174a !important;
    border: 1px solid #68174a;
    
} */

@media (min-width:320px) and (max-width:490px){
    .add-int-li-pro{
        font-size: 13px;
        padding: 2px;
       
    }
}
.close{
    padding: 0.5rem;
    border-radius: 5px;
border:none;
    color:black;
    cursor: pointer;
    background-color: transparent
   
}