




.d-pad-mar{
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    margin: 1rem 0;
}


.flex-pa-ma{
    display: flex;
    justify-content: space-between;
}

.flex-pa-ma h5 {
    color: #68174a;
}

.flex-pa-ma span {
    color: #ef4625;
    border-bottom: 1px solid #ef4625;
    margin-left: 2rem;
    margin-bottom: 1rem;
}


.flex-p-overview-me{
    background-color: #fde8eb;
    padding: 2rem;
    border-radius: 1rem;
    height: 63rem;
}