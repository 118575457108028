.style{
    /* display: flex; */
    justify-content: space-between;
}
.itemContainer{
    display: flex !important;
}
.styles{
    display : flex;
    justify-content: space-between;
}
.profile h4{
    color:#68174a;
}