/* #ef4625

#68174a

#fbcccc

#fde8eb


*/

@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
/* .ei2{
    padding-top: 0px !important;
    margin-top: 0px !important;
} */
.setBackground {
    background-color: #fbcccc !important;
}

.unskew {
    transform: skew(0deg, 5deg);
}

.skew {
    transform: skew(0deg, -5deg);
}


.job-search-pad {
    transform: scale(0deg, -5deg);
}

.Job-Details .jd-heading {
    text-align: center;
    color: #ef4625;
    background-color: #fde8eb;
    padding: 3rem 5rem;
    font-family: 'new_york_mediumbold';
}


.welcome-m {
    /* text-align: center; */
    /* padding: 5rem; */
    background-color: #fbcccc;
}


/*UI/UX Desginer Full Time*/

.job-search-pad {
    padding: 5rem 5rem 3rem 5rem;
    background-color: #fbcccc;
    transform: skew(0deg, -5deg);
}

.kakakak{
    transform: skew(0deg, 5deg);
}

.job-details-content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;

}

.jd-content-left {
    display: flex;
    align-items: center;
}

.jd-content-right button {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: #fde8eb;
    /* margin-right: 2rem; */
}

.app-now-api {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: #fde8eb;
}

.save-jpb-api {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: white;
    color: #ef4625;
    margin-right: 2rem;
}

.jd-content-left-text h {
    color: #ef4625;
    font-family: 'new_york_mediumbold';
}

.link-icon {
    color: #0077b5;
}

.jd-content-f {
    display: flex;
    justify-content: space-between;
    font-family: 'sf_ui_displayregular';
}

.jd-content-f span {
    margin-right: 10px;
    font-size: .8rem;
}

.job-details-pad {
    padding: 0 5rem 5rem 5rem;
    background-color: #fbcccc;
    transform: skew(0deg, -5deg);
}

/* .job-details-sub-content{
    display: flex;
    justify-content: space-between;
   
} */

/* .job-details-sub-content-left { */
    /* width: 70%; */

/* } */

.jd-left-border {
    border: 2px solid gray;
    border-radius: 10px;
    padding: 1rem;
}

.jd-left-border h2 {
    font-family: 'new_york_mediumbold';
}

/* .job-details-sub-content-right { */
    /* width: 30%; */
/* } */

.ei-flex {
    display: flex;
    justify-content: space-between;
}

.ei1 {
    width: 50%;
    margin-right: 1rem;
}

.ei2 {
    width: 50%;
    margin-left: 1rem;
}

.ei1-f,
.ei2-f {
    display: flex;
    justify-content: left;
}

.ei1-f p,
.ei2-f p {
    font-family: 'sf_ui_displayregular';
}

.ei-head {
    color: #ef4625;
    font-weight: bold;
    min-width: 5rem;
    margin-right: 1rem;
}

.wel-head {
    font-family: 'new_york_mediumbold';
    color: #ef4625;
}

.wel-para {
    font-family: 'sf_ui_displayregular';
}

.wel-list li {
    font-family: 'sf_ui_displayregular';
}

.jd-wel-flex {
    display: flex;
    justify-content: space-between;
}

.btn-jd-wel {
    display: flex;
    justify-content: space-between;
    /* width: 35%; */
}

.jd-wel-btn-apply {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: #fde8eb;
    margin-right: 2rem;
}

.jd-wel-btn-save {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: white;
    color: #ef4625;
    margin-right: 2rem;
}

.jd-wel-btn-savee {
    /* padding: 10px 25px;  */
    border-radius: 10px;
    border: none;
    background-color: white;
    color: #ef4625;
    margin-right: 2rem;
}

.share-icon {
    display: flex;
    justify-content: space-between;
    width: 30%;
    /* border: 1px solid red; */
}
.flex{
    display: flex;
}

.share-icon p {
    color: #ef4625;
    padding-top: 0px !important;
    /* border: 1px solid green; */
}
/* .bbg{
    display: flex;
} */
.share-icn-brd {
    border: 1px solid black;
    border-radius: 50%;
    padding: .1rem;
    margin: .1rem  0rem!important;
}

.jd-right-e-name {
    border: 2px solid gray;
    border-radius: 10px;
    padding: 1rem;
    margin-left: 2rem;
}

.jd-r-e-name-flex {
    display: flex;
    /* justify-content: space-between; */
}

.star-icn {
    color: #ef4625;
    border: 2px solid gray;
    border-radius: 10px;
    margin-right: 1rem;
}

.e-name {
    align-items: center;
}

.e-name h4 {
    color: #ef4625;
    font-family: 'new_york_mediumbold';
}

.e-name h5 {
    font-family: 'new_york_mediumbold';
}

.e-name p {
    color: #ef4625;
    border-bottom: 1px solid #ef4625;
    width: 70%;
}

.map-icn {
    width: 100%;
    height: 20vh;
}


.jd-right-similar {
    border: 2px solid gray;
    border-radius: 10px;
    padding: 1rem;
    margin: 0 0 0 2rem;

}

.jd-right-similar .similar-head-top {
    font-family: 'new_york_mediumbold';
    color: #ef4625;
}

.similar-flex {
    display: flex;
}

.similar-head h3 {
    font-size: 1rem;
    font-family: 'new_york_mediumbold';
}
/* .similar-head{
    width: 100%;
} */

.loc-similar {
    display: flex;
    justify-content: space-between;
    font-size: .6rem;
}

.similar-hour {
    display: flex;
    justify-content: space-between;
}

.s-hour {
    display: flex;
}

.sim-apply button {
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    background-color: white;
    color: #ef4625;
    /* margin-right: 2rem; */
    margin: 4px -10px 0 0;
    font-size: .8rem;
}

.si-money {
    color: #ef4625;

}

.s-hour h5 {
    font-family: 'new_york_mediumbold';
}

.ap-icn {
    text-align: center;
    color: #ef4625;
}

.icn-icn {
    font-size: 3rem !important;
}


/* Featured Jobs */

.b-h-bacj{
    background-color: #fde8eb;
}

.Featured-Jobs {
    padding: 5rem 5rem;
    background-color: #68174a;
    transform: skew(0deg, -5deg);
}

.Featured-Jobs .fj-heading,
.Featured-Jobs .fj-para {
    text-align: center;
    color: #fde8eb;
}

.Featured-Jobs .fj-heading {
    font-family: 'new_york_mediumbold';
}

.Featured-Jobs .fj-para {
    font-family: 'sf_ui_displayregular';
}

.cen-btn {
    text-align: center;
    margin-top: 2rem;
}

.fj-btn {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: #fde8eb;
    margin-right: 2rem;
}


/* popup */

.popup select {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 40%;
}

.popup input {
    padding: 0.2rem .5rem;
    margin: .5rem 0 .5rem 0;
    width: 100%;
}

.ok_popup {
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid #ef4625;
    background-color: #ef4625;
    color: white;
    font-size: 1rem;
    width: 100%;

}

.saveup-cen {
    text-align: center;
}

.popup-ap {
    text-align: center;
}

.popup-h2-ap {
    color: #68174a;
}

/*git*/


.git-flex2-img111 {
    position: absolute;
    top: 5rem;
    left: 0rem;
    z-index: 2;
}

.git-flex2-img222 {
    position: absolute;
    top: 13rem;
    left: 5rem;
    z-index: 0;
}







@media screen and (max-width:414px) {

    .job-details-content {
        flex-direction: column;

        
    }
    .job-details-pad{
        margin: 10px 10px;
    }
    .save-jpb-api{
        margin: 10px 10px;
    }

    /* .jd-content-left { */
        /* flex-direction: column; */
    /* } */

    .jd-content-right button {
        padding: 10px 25px;
        border-radius: 10px;
        border: none;
        background-color: #ef4625;
        color: #fde8eb;
        width: 100%;
        /* margin-right: 2rem; */
    }

    .job-details-sub-content {
        flex-direction: column;

    }

    .ei-flex {
        flex-direction: column;
    }

    .ei1 {
        width: 100%;
        /* margin-right: 1rem; */
    }

    .ei2 {
        width: 100%;
        margin-left: 0;
    }

    .jd-wel-flex {
        flex-direction: column;
    }

    .jd-wel-btn-apply {
        width: 42%;
    }

    .jd-wel-btn-save {
        width: 42%;
        margin-right: 0rem;
    }

    .share-icon {

        width: 100%;
        padding: 1rem;
    }

    .e-name p {
        width: 83%;
    }

    .sim-apply button {
        margin: 4px 0px 0 0;
        font-size: .8rem;
    }
}



@media screen and (max-width:767px) {

    .job-details-content {
        flex-direction: column;

    }
    .save-jpb-api{
        margin: 10px 10px;
    }
    .save-jpb-api{
        margin: 10px 10px;
    }

    /* .jd-content-left { */
        /* flex-direction: column; */
    /* } */

    .jd-content-right button {
        padding: 10px 25px;
        border-radius: 10px;
        border: none;
        background-color: #ef4625;
        color: #fde8eb;
        width: 100%;
        /* margin-right: 2rem; */
    }

    .job-details-sub-content {
        flex-direction: column;

    }

    .ei-flex {
        flex-direction: column;
    }

    .ei1 {
        width: 100%;
        /* margin-right: 1rem; */
    }

    .ei2 {
        width: 100%;
        margin-left: 0;
    }

    .jd-wel-flex {
        flex-direction: column;
    }

    .jd-wel-btn-apply {
        width: 42%;
    }

    .jd-wel-btn-save {
        width: 42%;
        margin-right: 0rem;
    }

    .share-icon {

        width: 100%;
        padding: 1rem;
    }

    .e-name p {
        width: 83%;
    }

    .sim-apply button {
        margin: 4px 0px 0 0;
        font-size: .8rem;
    }


}


@media screen and (max-width:991px) {
    .day-cards {
        flex-direction: column;
    }

    .day-card-flex {
        width: 100%;
    }
    .save-jpb-api{
        margin: 10px 10px;
    }
    .save-jpb-api{
        margin: 10px 10px;
    }
    .jd-right-e-name {
        margin-left: unset;
    }

    .jd-right-similar {
        margin: 2rem 0 0 0;
    }

    .similar-flex {
        display: flex;
        justify-content: center;
        /* justify-content: space-between; */
    }
    .git-flex1{
        margin-left: 6rem;
    }
}

@media screen and (max-width:485px) {
    .git-flex1{
        margin-left: -0rem !important;
        display: block !important;
        padding: 15px !important;

    }
    .name select {
        margin: 5px 0;
        padding: 10px 15px;
        width: 100% !important;
        border-radius: 5px;
        border: 2px solid #68174a;
        margin-top: -0.5rem !important;
    }
    .git-flex1 textarea {
        width: 90% !important;
        margin-right: 2.5rem;
    }
}