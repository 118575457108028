#opt-applied {
    border: none;
    background: none;
    outline: none;
}

.pad-col-flex-appl {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.short-div-apll {
    display: flex;
    border: 1px solid black;
    padding: .2rem .5rem;
    border-radius: 5px;
}

.pad-col-dash-appl {
    padding: 2rem;
    background-color: #fde8eb;
    border-radius: 1rem;
}

.flex-col-jb {
    display: flex;
    /* border: 1px solid gray; */
    /* padding: .2rem .5rem; */
    border-radius: 5px;
    /* justify-content: space-around; */
}

.flex-col-jb select {
    outline: none;
    border: none;
    font-size: .7rem;
    /* padding: .2rem .5rem; */
}

.flex-col-jb span {

    font-size: .7rem;
    /* border: 1px solid #ef4625;
    background-color: #ef4625; */
    padding: .2rem .5rem;
    border-radius: 5px;
}


.flex-p-overview-m {
    background-color: #fde8eb;
    padding: 2rem;
    border-radius: 1rem;
    /* height: 49rem; */
}

/* * {
     border: 1px solid black 
} */

/* media query */

@media screen and (max-width:991px) {

    .pad-col-flex-appl {
        flex-direction: column;
    }

    #opt-applied {
        width: 83%;
    }

    .flex-col-jb select {
        width: 82%;
    }

    .flex-p-overview-m {
        height: unset;
        margin-bottom: 2rem;
    }

}