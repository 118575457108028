.internship {
    background-color: #fde8eb;
    padding: 3rem 5rem;
}

.Updates{
    padding: 0.5rem;
    border-radius: 5px;
border:none;
    color: white;
    cursor: pointer;
    background-color: rgb(239, 70, 37);
}
.intern-form {
    background-color: white;
    padding: 3rem;
    /* margin: 3rem 5rem 0 5rem; */
    border-radius: 15px;
}
.deleteIcon:hover{
    color:#0d6efd;
}

.int-li-pro {
    font-family: 'new_york_mediumbold';
    color: #ef4625;
}

.handleDelete {
    display: flex;
    justify-content: flex-end;
}

.deleteIcon {
    color: gray;
}

.deleteIcon:hover {
    cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.skip-button {
    font-family: 'new_york_mediumbold';
}

.skip-button button {
    font-size: 1.1rem !important;
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: white;
    color: #ef4625;
    border: 1px solid #ef4625;
    /* margin-right: 5rem;
    margin-left: 1.5em; */
    width: 100%;
}

.add-int-li-pro {
    font-family: 'new_york_mediumbold';
    color: white;
    float: right;
    border: 1px solid  #68174a;
    background-color: #68174a;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
}

.add-int-li-pro:hover {
    color: white;
    border: 1px solid white;
    background-color: #ef4625;
    transition: 1s;

}

.title-other label {
    float: left;
    color: #68174a;
    font-weight: bold;
}

.title-other input {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid gray;
    color:grey;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
    float: left;
}

.des-other label {
    float: left;
    color: #68174a;
    font-weight: bold;
}

.des-otherr label {
    float: left;
    color: #68174a;
    font-weight: bold;
}

.dec-des-dec{
    width: 100%;
    padding: .6rem !important;
    margin: .5rem 0 .5rem 0;
    /* background-color: white !important; */
    color: gray !important;
    /* border: 1px solid gray !important; */
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: right; */
    border-radius: 5px;
}

.des-other textarea {
    width: 100%;
    padding: .6rem !important;
    margin: .5rem 0 .5rem 0;
    /* background-color: white !important; */
    color: gray !important;
    /* border: 1px solid gray !important; */
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: right; */
    border-radius: 5px;
}

.title-other select {
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    /* box-shadow: 0px 0px 2px 1px gray; */
    float: left;
}



/* media query */

@media screen and (max-width:991px) {
    .intern-form {
        margin: 1rem 0 0 0;
    }
}

@media screen and (max-width:768px) {}