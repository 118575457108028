/* #ef4625

#68174a

#fbcccc

#fde8eb


*/

/* @font-face {
    font-family: 'newyorkregular';
    src: url('../fonts/newyork_personal_use-webfont.woff2') format('woff2'),
         url('../fonts/newyork_personal_use-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

} */

@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.About-Our-Company {
    padding: 2rem 0rem !important;
    margin-top: 100px;
}

.aoc-para {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: -30px;
}

.About h1 {
    text-align: center;
    color: red;
    background-color: #fde8eb;
    padding: 3rem 5rem 3rem 5rem;
    font-family: 'new_york_mediumbold';
}

.col-back-soft {
    background-color: #fde8eb;
    /* padding-left: 90px; */
}




/*About-Our-Company */

.About-Our-Company {
    background-color: #68174a;
    color: #fde8eb;
    padding: 100px 50px !important;
    /* text-align: center; */
    transform: skew(0deg, -5deg);

}

/* .contain{
    padding: 8rem 1rem !important;
   background-color: red;
} */

.abot {
    transform: skew(0deg, 5deg);
}

.About-Our-Company .aoc-heading {
    font-family: 'new_york_mediumbold';
    color: white;
}

.About-Our-Company .aoc-para {
    font-family: 'sf_ui_displayregular';
    margin: 3rem 0;
    display: flex;
    font-size: 20px;
    justify-content: center;
    text-align: center !important;
    margin-top: 50px !important;
}

.About-Our-Company .aoc-heading,
.About-Our-Company .aoc-para {
    text-align: center;

}

.aoc-flex {
    display: flex;
    justify-content: space-between;
    /* padding: 2rem 0 0 0; */
}

.container {
    /* border: 1px solid red; */
    /* width: 90% !important; */
    /* margin: 0px !important; */
    padding: 0px !important;

}


.aoc-flex-img {
    width: 50%;
    /* padding-right: 2rem; */
}

.aoc-flex-img img {
    width: 100%;
    /* padding-right: 2rem; */
    border-radius: 25px;
}

.aoc-flex-text {
    width: 100%;
    padding-left: 2rem;
}

.aoc-flex-text h2 {
    font-family: 'new_york_mediumbold';
}

.aoc-flex-text p {
    font-family: 'sf_ui_displayregular';
    text-align: justify;
}

.aoc-flex-text h2,
.aoc-flex-text p {
    width: 100%;
}

.aoc-btn-flex {
    display: flex;
}

.aoc-btn-contact {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: #fde8eb;
    margin-right: 2rem;
}

.aoc-btn-learn-more {
    border: none;
    background: none;
    border-bottom: .1em double #fde8eb;
    color: #fde8eb;
}



/*What We Can Do?*/


.wwcd-pad {
    padding: 3rem 0rem;
    background-color: #fde8eb;
}

.What-We-Can-Do {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start !important;

}

.What-We-Can-Do h2 {
    color: #ef4625;

}

.full {
    /* border: 1px solid red; */
    margin-left: 0px;
    font-size: 20px;
    font-family: 'new_york_mediumbold'

}

.featuresContainer {
    display: flex;
}

.features-text {
    margin-left: 20px !important;
}

.img_f {
    width: 25px;
    height: 25px;

    /* padding: 2px;
    background-color: white;
    border-radius: 50%; */
}

/* .What-We-Can-Do{
    border: 1px solid red;
} */
.wwcd-text {
    padding-top: 40px !important;

}

.wwcd-text h2 {
    font-family: 'new_york_mediumbold';
    text-align: left;
    padding-left: 3rem;
}

.wwcd-text p {
    font-family: 'sf_ui_displayregular';
    text-align: justify;
    padding-left: 3rem;
}

.wwcd-text {
    padding-top: 2rem;
    width: 50%;
    padding-right: 3rem;
    /* border: 1px solid red; */
}

.wwcd-img {
    width: 50%;
    padding-top: 30px !important;
}

.wwcd {
    padding: 10px 10px;
    justify-content: center;
    text-align: center;
}

.wwcd-img img {
    width: 100%;
    border-radius: 25px;
}

.wwcd-btn-flex {
    display: flex;
}

.wwcd-btn-search {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: #fde8eb;
    margin-right: 2rem;
}

.wwcd-btn-learn-more {
    border: none;
    background: none;
    border-bottom: .1em double #ef4625;
    color: #ef4625;
}



/* Our Happy Customer */


/* .ohc-pad {} */


.Our-Happy-Customer {
    background-color: #68174a;
    color: #fde8eb;
    padding: 5rem 5rem;
}

.Our-Happy-Customer .ohc-heading {
    font-family: 'new_york_mediumbold';
}

.Our-Happy-Customer .ohc-para {
    font-family: 'sf_ui_displayregular';
}

.Our-Happy-Customer .ohc-heading,
.Our-Happy-Customer .ohc-para {
    text-align: center;
}

.ohc-flex {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 0 0;
}

.customer {
    margin: 0 25px;
}

.customer blockquote {
    display: block;
    border-radius: 8px;
    position: relative;
    background-color: #fde8eb;
    color: black;
    padding: 25px 50px 30px 50px;
    font-size: 0.8em;
    font-weight: 500;
    margin: 0;
    line-height: 1.6em;
    font-family: 'sf_ui_displayregular';
    height: 13rem;
    /* font-style: italic; */
}

.arrow-rot {
    transform: rotate(90deg);
    margin-left: 15px;
    margin-top: -14px;
}

.cus-img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin-right: 10px;
}

.author h5 {
    font-family: 'new_york_mediumbold';
}

.author span {
    font-size: .6rem;
}

.author-flex {
    display: flex;
}

.ohc-load-more {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: #fde8eb;
    margin-right: 2rem;
}

.btn-ohc {
    margin-top: 3rem;
    text-align: center;
}


/*Get in Touch*/

.git-flex2-img11 {
    position: absolute;
    top: 5rem;
    left: 0rem;
    z-index: 2;
}

.git-flex2-img22 {
    position: absolute;
    top: 13rem;
    left: 5rem;
    bottom: 0rem;
    z-index: 0;

}





@media screen and (max-width:414px) {
    .aoc-flex {
        flex-direction: column;
    }

    .About-Our-Company .aoc-para {
        margin: 1rem;
    }

    .aoc-flex-img {
        width: 100%;
        padding-right: 0;
    }

    .aoc-flex-text {
        width: 100%;
        padding-left: 0;
        padding-top: 2rem;
    }

    .aoc-btn-flex {
        display: flex;
        justify-content: space-between;
    }


    .What-We-Can-Do {

        flex-direction: column;

    }

    .wwcd-text {
        width: 100%;
        padding-right: 0;
    }

    .wwcd-btn-flex {
        display: flex;
        justify-content: space-between;
    }

    .wwcd-img {
        width: 100%;
        padding-left: 0;
        padding-top: 3rem;
    }

    .ohc-flex {
        flex-direction: column;
    }

    .ohc-p-cus {
        margin-bottom: 2rem;
    }

    .wwcd-img {
        margin-top: 90px;
    }


}




@media screen and (max-width:767px) {
    .aoc-flex {
        flex-direction: column;
    }

    .About-Our-Company .aoc-para {
        margin: 1rem;
    }

    .aoc-flex-img {
        width: 100%;
        padding-right: 0;
    }

    .aoc-flex-text {
        width: 100%;
        padding-left: 0;
        padding-top: 2rem;
    }

    .aoc-btn-flex {
        display: flex;
        justify-content: space-between;
    }


    .What-We-Can-Do {

        flex-direction: column;

    }

    .wwcd-text {
        width: 100%;
        padding-right: 0;
        text-align: left !important;
        margin-top: 2rem;
    }

    .wwcd-btn-flex {
        display: flex;
        justify-content: space-between;
    }

    .wwcd-img {
        width: 100%;
        padding-left: 0;
        padding-top: 2rem;
    }

    .ohc-flex {
        flex-direction: column;
    }

    .ohc-p-cus {
        margin-bottom: 2rem;
    }

    .ohc-load-more {
        width: 100%;
    }

    .wwcd-img {
        margin-top: 90px;
    }

    .wwcd-pad {
        margin: 0px !important;
    }

}

@media screen and (max-width:1265px) {

    .git-flex2-img1 {
        position: absolute;
        top: 12rem;
        left: -1rem;
        z-index: 2;
    }

    .About-Our-Company .aoc-para {
        margin: 1rem;
    }

    .wwcd-img {
        margin-top: 90px;
    }

    .wwcd-pad {
        margin-top: 1rem !important;
    }

    .git-flex2-img2 {
        position: absolute;
        top: 17rem;
        left: 6rem;
        z-index: 0;
    }

    .git-flex2 img {
        width: 180px;
    }
}

@media screen and (max-width:992px) {
    .git-flex2 {
        display: none;
    }
}

.formItem {
    display: flex;
    flex-direction: column;

}

/* .formItem .getInTouchFormField {
    width: 100%;
} */