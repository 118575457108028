/* #ef4625

#68174a

#fbcccc

#fde8eb


*/




@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

* {
    margin: 0;
    padding: 0;
}

.footer-f {
    background-color: #68174a;

    padding: 3rem 5rem;
    /* position: absolute; */
    position: relative;
    display: flex;
    justify-content: center;
  
  
   
}

.footer {
    display: flex;
    justify-content: space-between;
    border-top: unset !important;
    line-height: unset !important;
 
    
}

.footer h5 {
    color: #ffffff;
    font-size: 1rem;
    margin-bottom: 30px;
}

.footer p {
    color: #fde8eb;
    font-size: .8rem;
}

.footer li {
    color: #fde8eb;
    font-size: .8rem;
    cursor: pointer;
}

.footer li:hover {
    color: #ffb1bd;
    font-size: .8rem;
}

.logo-footer {
    width: 50%;
    padding-right: 5%;
}

.f-logo img {
    width: 275px;
    /* padding-left: 15px; */
    /* margin-bottom: 20px; */
    cursor: pointer;
    margin-left: -60px;
}

.Resources {
    width: 19%;
}

.Resources h5 {
    color: #ef4625;
}

.Resources li {
    list-style: none;
}

.Community {
    width: 19%;
}

.Community li {
    list-style: none;
}

.Quick_Links {
    width: 19%;
}

.Quick_Links li {
    list-style: none;
}

.Social {
    width: 19%;
}

.Social .icon {
    background-color: #68174a;
    color: #fde8eb;
    border: 1px solid #fde8eb;
    border-radius: 50%;
    margin-right: 5px;
    padding: 4px;
}

.para span {
    font-size: .8rem;
    color: #fde8eb;
    
}
.para{
    text-align: left;
    margin-left: -40px;
}
.hr-f {
    margin: 25px 0;
    margin-left: -30px;
    color: white;
}

.bottom-f {
    position: absolute;
    display: flex;
    padding-top: 40px;
    bottom: 20px;
    left: 80px;
    justify-content: space-between;
}

.bottom-f span {
    color: #fde8eb;
    font-size: .8rem;
}

.bottom-f ul {
    display: flex;
    justify-content: space-between;
}

.bottom-f li {
    list-style: none;
    padding-left: 55px;
}


/* media query */

@media screen and (max-width:768px) {
   

    .footer {
        flex-direction: column;
    
    }

    .bottom-f {
        flex-direction: column;
    }

    .bottom-f p {
        text-align: center;
        padding: 10px 0;
    }

    .logo-footer {
        width: 100%;

    }

    .mobile-flex {
        display: flex;
    }

    .Resources h5,
    .Community h5,
    .Quick_Links h5,
    .Social h5 {
        margin: 10px 0 5px 0;
    }

    .Resources,
    .Community,
    .Quick_Links,
    .Social {
        width: 100%;
    }

    .bottom-f li {
        padding-left: 0;

    }

    .bottom-f ul {
        padding-left: 0;
    }
}