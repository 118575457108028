

.main-recent-job{
    background-color: #fde8eb;
    padding: 5rem;
}

.recent-job-card{
    background-color: white;

    width: 300px;
    height: 400px;
    padding:  20px;
    /* flex-basis: 24%; */
    border-radius: 10px;
    margin-bottom: 20px;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* margin-left: 10px; */
    /* box-shadow: 0px 0px 30px rgba(0, 0, 0, alpha); */
}

.fl-lo-ca{
    display: flex;
}

.logaa img {
    width: 50px;
}

.candl{
    margin-left: 1rem;
}

.candln{
    font-size: 16px;
    font-weight: bold;
    font-family: 'new_york_mediumbold';
}

.candll{
    font-size: 14px;
    color: gray;
    font-family: 'sf_ui_displayregular' !important;
}

.jo-ttitle{
    color: #ef4625;
    font-weight: bold;
    font-size: 18px !important;
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
}

.emt{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: gray;
}

.emt-type{
    width: 55%;
    font-family: 'sf_ui_displayregular' !important;
}

.emt-time{
    width: 45%;
    font-family: 'sf_ui_displayregular' !important;
}

.shhot{
    font-size: 16px !important;
    font-family: 'sf_ui_displayregular' !important;
    margin: 1rem 0;
}

.fld{
    display: flex;
    justify-content: space-between;
}

.fld span
{
    padding: 10px 5px;
    color: orangered;
    background-color: #fbcccc;
    border: 2px solid #fbcccc;
    border-radius: 5px;
    font-family: 'new_york_mediumbold';
    font-size: 14px;
}

.alumni{
    color: #ef4625;
    font-weight: bold;
    font-size: 18px !important;
    font-family: 'new_york_mediumbold';
}