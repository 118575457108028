



/* .pad-col-dash-appl{

} */

.generate-cv-pro{
color: #68174a;
}

.btn-n-pad-mar-a{
    padding: 10px 25px; 
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: white;
    /* margin-right: 5rem; */
    /* margin-left: 1.5em; */
    font-family: 'new_york_mediumbold';
}


.flex-p-overview-mh{
    background-color: #fde8eb;
    padding: 2rem;
    border-radius: 1rem;
    /* height: 76rem; */
    margin-bottom: 2rem;
}