@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.success-message {
    color: #00a67d;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.main-header-container {
    display: flex;
    align-items: flex-start;
}

h1,
h2 {
    font-family: 'new_york_mediumbold';

}

.col-h2 {
    color: #fde8eb;
}

p {
    font-family: 'sf_ui_displayregular';
}

.pp22 {
    margin-left: 30px !important;
}

.rel-pad-p {
    position: relative;
}

.fo-si-ho {
    font-size: 30px;
}

.globalPadding {
    /* padding: 100px 0px !important; */
    padding: 140px 0px !important;

}

.globalPaddingg {
    padding: 90px 0px 0px 0px !important;
    /* border: 1px solid red; */
}

.topContainer {
    padding: 50px 0px 150px 0px !important;
}


.college-carousel {
    height: 200px;
    width: 240px;
    /* width: 300px !important; */
    /* border: 1px solid red; */
    /* padding: 10px; */
    border-radius: 6px;
}


@media only screen and (max-width: 600px) {
    .our-recuritment-container {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 140%;
    }
}

.college-carousel {
    min-height: 100px;
    max-height: 200px;
    max-width: 200px;


}

p {
    font-size: 16px;
}

.college-carousel {
    /* border: 1px solid red; */
    padding: 0px 40px !important;

    padding-top: 60px !important;
    padding-bottom: 30px !important;
}

.carousel-container {
    width: 100%;
    height: 100%;
    padding: 50px 0;
    padding-left: 326px;
    /* border: 2px solid red; */
    justify-content: space-between;
    /* justify-content: center; */
    /* justify-content: right; */
}

.college-carousel-container {
    max-width: 200px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    /* margin-left: 60px; */
    /* margin: 0px 20px !important; */


}

.carousel-indicators li {
    display: none;
}


.h-pad {
    padding: 5rem 5rem 5rem 5rem;
}

.h-col1 {
    background-color: #fde8eb;
    z-index: 3;

}

.text {
    width: 100%;
}

.premium {
    font-size: 2.6rem;
    color: #ef4625;
    font-family: 'new_york_mediumbold';
}

.jobs {
    font-size: 2.6rem;
    margin-top: 15px;
    font-family: 'new_york_mediumbold';
    color: #ef4625;
}

.quest {
    /* width: 100%; */
    margin-top: 30px;
    font-family: 'sf_ui_displayregular';
    color: #68174a;
}

.photo {
    position: relative;
}

.photo .h-imgg {
    width: 400px;
    border-radius: 15px;
    margin-top: 40px;
}

.img1 {
    position: absolute;
    top: -3rem;
    right: 6rem;
    z-index: 2;
}

.img2 {
    position: absolute;
    bottom: -4rem;
    right: 0rem;
    z-index: 0;
}

.search-bar-h {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    background-color: white;
    padding: 10px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 35px rgba(0, 0, 0, .1);
    /* box-shadow: 5px 5px 5px #aaaaaa; */
}

.carousel-item-padding-40-px {
    padding: 20px !important;
    /* margin: 0px !important; */
}

.home-main-container {
    background-color: #fde8eb;
}

.search-bar-h button {
    background-color: #ef4625;
    color: #fde8eb;
    border-radius: 8px;
    border: none;
    text-transform: capitalize;
    font-family: 'new_york_mediumbold';
}

.search-bar-h input {
    border: none;
    width: 6rem;
}

.register-now-btn {
    padding: .5rem 1rem;
    border: 1px solid #fde8eb;
    border-radius: 10px;
    color: #fde8eb;
    background-color: #68174a;
    font-weight: bold;
}

.register-now-btn:hover {
    border: 1px solid #ef4625;
    color: #ef4625;
}

.no-outline {
    border: none;
    width: 60%;
}

.img-our-req {
    width: 100%;
    height: 400px;
    /* border: 1px solid red; */
    padding-left: 50px !important;

}

.no-outline:focus {
    outline: none;
}

.btn-serch-icn {
    margin-top: 7px;
    color: gray;
    /* font-size: 10px; */
}

.popular {
    font-weight: bold;
    color: rgb(32, 1, 32);
    margin: 10px 0;
    font-family: 'sf_ui_displayregular';
}

.popular span {
    border-bottom: 1px solid rgb(32, 1, 32);
}

.sec-line {
    height: 50px;
    background-color: #ef4625;
    opacity: 0.6;
    transform: skewY(-5deg);
    transform-origin: 20% 40%;
}

.nn {
    background-color: #68174a;
    padding: 5rem 5rem 5rem 5rem;
    transform: skew(0deg, 5deg);
    margin-top: -2px;
}

.why {
    padding: 40px 0px;
}

.why-choose-us {
    background-color: #68174a;
    padding: 5rem 5rem 5rem 5rem;
    transform: skew(0deg, -5deg);
    margin-top: -40px;


    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    background-color: #68174a;
    padding: 5rem 5rem 5rem 5rem;
    /* transform: skew(0deg, -5deg); */
    box-shadow: 1px 0px 30px rgb(0, 0, 0, .5);
}



.why-choose-us .mp-h {
    color: #fde8eb;
    text-align: center;
}

.why-choose-us .mp-p {
    color: #fde8eb;
    text-align: center;
}

.text {
    padding-right: 5rem !important;
    /* border: 1px solid red; */
}

.why-choose-us span {
    color: #ef4625;
}

.card {
    /* padding: 15px 10px 15px 10px; */
    /* height: 280px !important; */
    height: 300px !important;
    /* height: auto !important; */
    text-align: center;
    background-color: #fde8eb;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.card p {
    padding: 0px 5px !important;
    /* text-align: justify; */
}

.dark-head {
    color: #68174a;
    /* border: 1px solid green; */
    /* height: 65px; */
}

.img-Card {
    min-height: 100px;
    /* min-height: auto; */
    /* border: 1px solid red; */
    width: 100px;
    margin: 0px auto;
    margin-top: -65px;
    border-radius: 50%;
    background: white;
    text-align: center;
    padding: 6%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.img-new {
    width: 100%;
}

.flex-mp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0;
}

.flex-mp h1,
.flex-mp p {
    color: #fde8eb;
    padding: 0 0 10px 0;
}

.flex-mp p {
    width: 90%;
}

.text-mp {
    width: 50%;
}

.photo-mp {
    width: 50%;

}

.photo-mpp {
    width: 50%;

}

.img-hide {
    display: none;
}

.text-mp button {
    background: none;
    border: none;
    color: #ef4625;
}

.mp-img {
    float: right;
    margin-right: 20px;
    width: 450px;
    box-shadow: 5px #fde8eb;
    border-top-left-radius: 50px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 15px;
    object-fit: cover;
}

.mp-img1 {
    float: left;
    margin-right: 20px;
    width: 450px;
    box-shadow: 5px #fde8eb;
    border-top-left-radius: 50px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 15px;
    object-fit: cover;
}

/* .pro-pad{
    padding-bottom: 2rem;
} */

.vv span {
    background-color: red;
    height: 25px;
    width: 25px;
    border-radius: 50%;
}

.mp-pad-right {
    padding: 0 100px 0 0;
}

.Premium-Membership {
    text-align: center;
    background-color: #fde8eb;
    padding: 5rem 5rem 5rem 5rem;
    margin-top: -2rem;
}

.Premium-Membership span {
    color: #ef4625;

}


.Premium-Membership p {
    margin-bottom: 3rem;
}

.pm-flex-main img {
    width: 200px;
    padding: 10px;
    border: 1px solid white;
    background-color: white;
    margin: 10px 0 0 0;
    /* box-shadow: 0px 0px 5px .1px gray; */
}


.pm-flex {
    display: flex;
    justify-content: space-between;
}

.carouselContainer {
    width: 100%;


}

.pm-flex-main h1 {
    font-size: 1rem;
    padding: 10px;
}

.pm-flex-main h5 {
    color: gray;
    padding: 0 0 10px 0;
}

.pm-btn {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: white;
    margin-top: 1rem;
    font-size: 1.1rem !important;
}

.c-name {
    margin-top: 1rem;
    color: #68174a;


}

.pro-pad {
    background-color: #68174a;
    padding: 5rem 5rem 5rem 5rem;
    transform: skew(0deg, -5deg);
    margin-top: -30px;

}

.pro-pad img {

    transform: skew(0deg, 5deg);
}

/* .pro-pad .p-text {
    transform: skew(0deg, 5deg) !important;
} */
.getInTouchFormField {
    background-color: transparent;
    border: 1px solid #C4A8B4 !important;
    color: #000000 !important;
}

/* 
::placeholder {
    color: #000000 !important;
} */

.pro-pad h2 {

    transform: skew(0deg, 5deg);

}


.pro-padd {
    background-color: #e7d4d7;
    padding: 5rem 5rem 5rem 5rem;
}

.Problems {
    /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);  */
    display: flex;
    justify-content: space-between;
    align-items: flex-start !important;
    margin-top: -15px;

}

.lispacing {
    margin-top: 15px;
}

.p-img img {
    /* background-image: url("https://media.istockphoto.com/photos/photo-of-candidates-waiting-for-a-job-interview-picture-id1137038796?b=1&k=20&m=1137038796&s=170667a&w=0&h=Fb47hwsSnsd9z71cKAeucZxMX18Za7tBb2Qla1_FHjM="); */
    /* width: 50%; */
    margin-right: 5%;
    border-radius: 25px;
    /* background-repeat: no-repeat; */
}

.pp1 {
    background-color: #FCE8ED;
    width: 250px;
    text-align: center;
    height: 160px;
    padding: 10px;
    margin: 0px 10px;
    border-radius: 10px;
    position: absolute;
    top: -24rem;
    left: -3rem;
}

.pp1-icon {
    color: #ef4625;
}

.pp11-icon {
    color: #ef4625;
    text-align: center;

}

/* .pp22{
    padding-left: 20px !important;
} */

.pp1 p {
    font-size: 12px !important;
    color: #858283;
}

.pp1-flex {
    display: flex;
    align-items: center;
    color: #ef4625;
    /* border: 1px solid #68174a   ; */

}

.pp11 {
    background-color: #FCE8ED;
    text-align: center;
    width: 250px;
    height: 160px;
    padding: 20px;
    border-radius: 10px;
    position: absolute;
    top: -3rem;
    right: 0rem;
}

.pp11 h3 {
    color: #ef4625;
}

.pp11 p {
    font-size: 12px !important;
    color: #858283;
}

.pp2 {
    background-color: #FCE8ED;
    width: 250px;
    height: 160px;
    padding: 20px;
    border-radius: 10px;
    position: absolute;
    bottom: -3.5rem;
    right: -2rem;
    text-align: center;
    font-size: 10px;

}

li::marker {
    color: #ef4625;
}


.pp2 p {
    font-size: 12px !important;
    color: #858283;
}

.pp2 h3 {
    color: #ef4625;
}

.pp1 h3 {
    color: #ef4625;
}

.pp2-icon {
    color: #ef4625;

}

.pp22 {
    background-color: #FCE8ED;
    width: 250px;
    height: 160px;
    padding: 20px;
    border-radius: 10px;
    position: absolute;
    bottom: -3.5rem;
    right: 20rem;
    /* right: 24rem; */
    text-align: center;
    font-size: 10px;

}

.pp22 p {
    font-size: 12px !important;
    color: #858283;
}

.pp22 h3 {
    color: #ef4625;
}

.p-text {
    width: 50%;
    padding-left: 5%;
    margin-left: 100px;
}

.p-textt {
    width: 50%;
    /* padding-left: 5%; */
    /* margin-left: 100px; */
}

.p-text span {
    color: #ef4625;
}

.p-textt span {
    color: #ef4625;
}

.p-text h1,
.p-text p {
    margin: 5px 0;
    color: white;
}


ul li::before {
    color: #ef4625;
}

.p-text p {
    margin-bottom: 8rem;
}

.p-textt h1,
.p-textt p {
    margin: 5px 0;
    color: white;
}

.p-textt {
    width: 50%;
    /* padding-left: 5%;
    margin-left: 100px; */
}

.p-textt span {
    color: #ef4625;
}

/* .p-textt h1, .p-textt p{
    margin: 5px 0;
    color: black;
} */

.p-btn {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: white;
    margin-top: 3rem;
    margin-right: 1.5rem;
    /* margin-left: 1.5em; */
    font-size: 1.1rem !important;
}

.p-btn2 {
    color: #ef4625;
    border: none;
    background: none;
    border-bottom: .1em double #ef4625;
}

.Recruitment-Process {
    background-color: #fde8eb;
    padding: 5rem 5rem 1rem 5rem;
    text-align: center;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center !important; */
    /* flex-direction: column; */
}

.Recruitment-Process span {
    color: #ef4625;
}

.rp-same {
    width: 200px;
    height: 200px;
    border: 2px solid #ef4625;
    background-color: white;
    margin: 3rem 0;
    border-radius: 15px;
    padding: 15px;
}

.jobCardMainConatiner {
    /* border: 1px solid red; */
    padding: 22px !important;
}

.msg {
    font-weight: bold;
    color: #ff0000;
}

.rp-ar {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.rp-icn {
    /* margin-top: 3rem; */
    color: #ef4625;
}

.rp-flex {
    display: flex;
    justify-content: space-between;
}

/*day*/

.day {
    background-color: #68174a;
    /* padding: 5rem 5rem; */
    transform: skew(0deg, -5deg);
}

.react-multiple-carousel__arrow {
    background-color: #ef4625 !important;
    /* color: black !important; */
}

.react-multiple-carousel__arrow:hover {
    background-color: #68174a !important;
}

.day-btns {
    transform: skew(0deg, 5deg);

}

.day-cards-container {
    transform: skew(0deg, 5deg);
    cursor: pointer;
    /* border: 1px solid red; */
    display: wrap !important;
    justify-content: center !important;

}

.day h2 {
    transform: skew(0deg, 5deg) !important;
}

/* .day button {
    transform: skew(0deg, 5deg);

}


.day p {
    transform: skew(0deg, 5deg) !important;
}



.day .day-card-flex {
    transform: skew(0deg, 5deg);
} */


.day h2,
.day .day-parag {
    color: white;
    text-align: center;
}

.day span {
    color: #ef4625;
}

.day-btns {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.active {
    color: #ef4625;
    color: white;
}

.day-btns button {
    padding: 10px 20px;
    border: 1px solid white;
    background-color: #68174a;
    color: white;
    border-radius: 5px;
    margin: 5px;
    text-transform: capitalize;
}

.day-btns button:hover {
    color: #ef4625;
    border: 1px solid #ef4625;
}

.day-cards {
    /* border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 16px;
    margin-bottom: 16px; */
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    cursor: pointer;
    /* justify-content: space-between; */
    /* flex-direction: column; */
    align-items: center;
    width: 100% !important;
}



.day-card-flex {
    border: 1px solid white;
    background-color: white;
    width: 23%;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 1em;
    margin-left: 1em;
}

.day-card-flex h5 {
    color: #ef4625;
}

.day-card-icn {
    display: flex;
}

.social-icon {
    width: 50px;
    font-size: 3.5rem !important;
    color: rgb(1, 24, 1);
}

.card-flex h3 {
    font-size: 1rem;
}

.card-flex h6 {
    padding-top: .5rem;
}

.card-flex p {
    color: gray;
    font-size: .8rem;
}

.card-flex .align-icn {
    font-size: .9rem;
    align-items: center;
}

.day-card-flex .full {
    color: gray;
}

.full-flex {
    display: flex;
}

.f1 {
    margin-right: 15px;
}

.day-card-flex .day-para {
    color: black;
    padding: 5px 0;
}

.day-btn-flex {
    display: flex;
    justify-content: space-between;
}

.day-btn-flex button {
    padding: 5px 10px;
    background-color: #fde8eb;
    border: none;
    font-size: 11px;
}

.day-hour {
    display: flex;
}

.day-h-h {
    color: gray;
}

.day-btn-l {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0;
    bottom: 35px;
    position: absolute;
    width: 19%;
}

.day-btn-l button {
    padding: 10px 5px;
    color: orangered;
    background-color: #fbcccc;
    border: 2px solid #fbcccc;
    border-radius: 5px;
    font-family: 'new_york_mediumbold';
    font-size: 14px;
}

.see-m {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.see-m button {
    padding: 10px 20px;
    border: 1px solid #ef4625;
    background-color: #68174a;
    color: #ef4625;
    border-radius: 5px;
    margin: 5px;
}

.git-flex {
    display: flex;
}

.git-flex2 {
    position: relative;
}

.git-flex2 img {
    width: 250px;
    box-shadow: -5px 5px rgb(56, 1, 56);
    border-top-left-radius: 50px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 15px;
    margin: 5px 0;
}

.Get-in-Touch {
    background-color: #fde8eb;
    padding: 5rem 5rem;
    width: 100%;
}

.name {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.name select {
    margin: 5px 0;
    padding: 10px 15px;
    width: 49%;
    border-radius: 5px;
    border: 2px solid #68174a;
}

.name input {
    margin: 5px 0;
    padding: 10px 15px;
    width: 49%;
    border-radius: 5px;
    border: 2px solid #68174a;
}

/* .pp1,.pp2,.pp11,.pp22{
    padding: 10px !important;
   
    height: 100px;
    width: 200px;
} */
.cont {
    /* border: 1px solid red; */
    /* margin: 0px 50px; */
    justify-content: center;
    padding: 0px 50px !important;
}

.cont h2 {
    padding: 0px !important;
    margin: 0px !important;
    /* border: 1px solid goldenrod; */


}

.bba {
    /* border: 1px solid red; */
    /* margin-top: 0px !important;/ */
    padding-top: 0px !important;
}

.mg {
    border: 1px solid red;
    margin: 0px 50px;
    padding: 0px;
}

.pp1,
.pp2 {
    height: 150px;
    width: 230px;
    padding: 3px !important;

}

.pp11,
.pp22 {
    height: 150px;
    width: 230px;
    padding: 3px !important;
}

.pp11 {
    /* border: 1px solid red; */
    margin-top: 5rem;
    margin-right: -1rem;
}

.pp22 {
    margin-bottom: 2rem;
    margin-right: -2rem;
    /* margin-left: 5rem; */
}

.pp1 {
    margin-top: 20px;
    margin-left: -10px;
}

.p-img img {
    width: 100%;
    /* padding: 0px 40px; */
    /* border: 1px solid red; */
}

.name input:focus {
    /* outline: 1px solid rgb(213, 78, 123); */
    outline: none;
}

.git-flex1 {
    width: 70%;
}

.git-flex1 h1 {
    /* color: #68174a; */
    color: red;
}

.git-flex1 textarea {
    width: 90%;
    border: 2px solid #68174a;
    margin-top: 5px;
    padding: 10px 15px;
    border-radius: 5px;
}

.git-flex1 textarea:focus {
    /* outline: 1px solid rgb(213, 78, 123); */
    outline: none;
}

.git-btn-flex {
    display: flex;
    /* border: 1px solid red; */
    margin-top: 27px;
    /* justify-content: space-between; */
}

.git-btn-flex button {
    padding: 7px 16px;
    /* border: 1px solid orangered; */
    background-color: orangered;
    color: white;
    border: none;
    border-radius: 5px;
    margin: 0px 15px 0 0;
}

.git-btn-flex button:hover {
    background-color: #1976d2;
}

.git-flex1 .git-flex1-text {
    margin: 20px 0;
    color: #68174a;
    font-weight: bold;
}

.main_bt {
    justify-content: center;
    display: flex;
    /* border: 1px solid red; */
    margin-top: 50px;
}

.bt {
    flex-basis: 100%;
    text-align: left;

}

.kk {
    outline: hidden;
}

.iii {
    padding-left: -250px !important;
}

.btr {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: white;
    margin-left: 1.5em;
    font-family: 'new_york_mediumbold';
}

.bty {
    padding-left: -100px;
    border: none;
    background: transparent;
    padding-left: -100px !important;

    padding: 10px 25px;
    border-radius: 10px;
    color: #ef4625;
    margin-left: 1.5em;
    font-family: 'new_york_mediumbold';
}



.git-flex1 span {
    color: #ef4625;
}

.p_li span {
    text-align: justify !important;
    color: white;
    /* margin-left: 7px; */
}

.p_li label {
    margin-left: -20px;
    color: #ef4625;
    position:absolute;
     margin-top: 3px;
}

.git-flex2-img1 {
    position: absolute;
    top: 5rem;
    right: -15rem;
    z-index: 2;
}

.git-flex2-img2 {
    position: absolute;
    top: 13rem;
    right: -23rem;
    z-index: 0;
}

.svg-rec-img {
    width: 1672px;
    height: 762.868px;
}

/* media query*/

@media screen and (max-width:1200px) {
    .photo .h-imgg {
        width: 200px;
    }

    .card {
        margin: 40px 0px;
    }

    .img1 {
        top: 2rem;
        right: 8rem;
    }

    .img2 {
        bottom: 2rem;
        right: 0rem;
    }

    .p-img img {
        width: 80%;
    }

    .p-text {

        margin-left: 0;
    }

    .pp2 {
        position: absolute;
        /* bottom: -6rem;
        right: 1rem; */
    }

    .pp1 {
        position: absolute;
        top: -20rem;
        left: -3rem;
    }
    

    .pp1 h3 {
        color: #ef4625;

    }

    .p-textt p {
        width: 90%;
    }
}

@media screen and (max-width:1198px) {
    .day-cards-container {
        align-items: center;
    }

    .mm {
        margin: 30px 0px;
    }

    .jobCardMainConatiner {
        flex-basis: 32% !important;
    }
}

@media screen and (max-width:992px) {
    .mp-img {
        width: 300px;
    }

    .mm {
        margin: 30px 0px;
    }

    .photo .h-imgg {
        width: 180px;
    }

    .img1 {
        top: 2rem;
        right: 5rem;
    }

    .img2 {
        bottom: 0rem;
        right: 1rem;
    }

    .search-bar-h {
        font-size: 15px;
    }

    .search-bar-h input {
        width: 4rem;
    }

    .pp2 {
        position: absolute;
        bottom: -7rem;
        right: 1rem;
    }

    .pp1 {
        position: absolute;
        top: -17rem;
        left: -3rem;
    }
}

@media screen and (max-width:949px) {
    .jobCardMainConatiner {
        flex-basis: 45% !important;
    }

}

@media screen and (max-width:768px) {
    .photo {
        display: none;
    }

    .mm {
        margin: 30px 0px;
        /* flex-direction: column !important; */
    }

    .day-btns {
        flex-direction: column;
    }

    .day-cards-container {
        flex-direction: column;
    }

    .p-text {
        padding-left: unset;
    }

    .mp-img {
        width: 250px;
    }

    .text {
        width: 100%;
    }

    .quest {
        width: 100%;
    }

    .premium,
    .jobs {
        font-size: 2rem;
        text-align: center;
    }

    .search-bar-h {
        width: 100%;
    }

    .pm-flex {
        flex-direction: column;
        /* border: 2px solid red !important; */
    }

    .git-btn-flex {
        flex-direction: column;
    }

    .git-btn-flex button {
        padding-left: 5%;
        margin-right: 2.5rem;
    }

    .Problems {
        flex-direction: column;
    }

    .pp1 {
        position: unset;
        width: 100%;
        margin-top: 20px;
    }

    .pp2 {
        position: unset;
        width: 100%;
        margin-top: 20px;
    }

    .p-img {
        text-align: center;
    }

    .rel-pad-p {
        text-align: center;
    }

    .f-icon-flex {
        display: flex;
        justify-content: center;

    }

    .p-text {
        width: 100%;
        margin-top: 20px;
    }

    .globalPadding {
        padding: 60px 0 50px 0 !important;

    }


    .topContainer {
        padding: 50px 0px 50px 0px !important;
    }

}

@media screen and (max-width:600px) {
    .svg-rec-img {
        width: 272px;
        /* height: 762.868px; */
    }

    .mm {
        margin: 30px 0px;
    }

    .p-img img {
        width: 100% !important;


    }

    .img-hide {
        display: block;
    }

    .mp-img-hide {
        display: none;
    }
}

@media screen and (max-width:576px) {
    .text {
        width: 1000% !important;
        padding: 0 5% !important;
    }
    .no-outline {
        border: none;
        width: 70%;
    }

    .mm {
        margin: 650px 0px;
        /* flex-direction: column !important; */
    }
}

@media screen and (max-width:485px) {
    
   .git-flex{
    margin-left: 1rem !important;
   }
    .photo {
        display: none;
    }

    .mm {
        margin: 55px 0px;
    }


    .text {
        width: 100%;
    }

    .quest {
        width: 100%;
        text-align: center;
    }

    .premium,
    .jobs {
        font-size: 2rem;
        text-align: center;
    }

    .search-bar-h input {
        width: 50px;
        font-size: .7rem;

    }

    .search-bar-h {
        width: 100%;
        padding: 5px;
    }

    .search-bar-h Button {
        border-radius: 4px;
        width: 20px;
        font-size: .7rem;
        padding: 2px;
    }

    .flex-mp {
        flex-direction: column;
        text-align: center;
    }

    .text-mp {
        width: 100%;
    }

    .mp-pad-right {
        padding: 0;
    }

    .photo-mp {
        width: 100%;
        object-fit: contain;
    }

    .mp-img {
        width: 250px;
    }

    .text-mp button {
        margin-bottom: 10px;
    }

    .photo-mpp {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .img-hide {
        display: block;
    }

    .mp-img-hide {
        display: none;
    }

    .pm-flex {
        flex-direction: column;
    }

    .Problems {
        flex-direction: column;
    }

    .pp1 {
        width: 250px;
        height: 160px;
        padding: 20px;
        border-radius: 10px;
        position: absolute;
        top: 474em;
        left: 3rem;
    }

    .pp1 h2 {
        font-size: 20px !important;
    }

    .pp2 h2 {
        font-size: 20px !important;
    }

    .pp2 {
        width: 200px;
        padding: 20px;
        border-radius: 10px;
        position: absolute;
        top: 489em;
        right: 3rem;
        text-align: center;
    }

    .p-img img {
        width: 100%;
    }


    .p-text {
        width: 100%;
        margin: 90px 0 0 0;
    }

    .rp-flex {
        flex-direction: column;
        align-items: center;
    }

    .rp-ar {
        flex-direction: column;
    }

    .rp-icn {
        transform: rotate(90deg);
    }

    .rp-same {
        margin: 3rem;
    }

    .day-btns {
        flex-direction: column;
    }


    .day-card-flex {
        width: 80%;
    }

    /* .Problems{
    padding-bottom: 100px 0 !important;
} */

    .git-flex2 {
        display: none;
    }

    .git-flex1 {
        text-align: center;
        width: 100% !important;
        display: flex;
        justify-content: center;
    }


    .name {
        flex-direction: column;
        /* margin-left: 5%; */
    }

    .name input {
        width: 100%;
    }

    .git-flex1 textarea {
        width: 100%;
    }

}

@media screen and (max-width:375px) {
    .rp-flex {
        flex-direction: column;
        align-items: center;
    }

    .mm {
        margin: 60px 0px;
    }
}

@media screen and (max-width:1220px) {
    .ttrr {
        margin-right: -1rem;
    }
}

@media screen and (max-width:1200px) {
    .pp22 {
        bottom: -1rem;
        right: 17rem;
    }
}

@media screen and (max-width:992px) {
    .pp22 {
        bottom: -1rem;
        right: 12rem;
    }
}

@media screen and (max-width:835px) {
    .pp22 {
        bottom: 1rem;
        right: 12rem;
    }

    .pp11 {

        position: absolute;
        top: -4rem;
        right: -64px;
    }
}

@media screen and (max-width:810px) {
    .pp22 {
        bottom: 3rem;
        right: 9rem;
    }
}

@media screen and (max-width:767px) {
    .rel-pad-p {
        position: unset;
    }

    .pp11 {
        position: unset;
        top: unset;
        right: unset;
        width: 100%;
        margin-top: 1rem;
    }

    .pp22 {
        position: unset;
        bottom: unset;
        right: unset;
        width: 100%;
        margin-top: 1rem;
    }

    .p-img img {
        margin-top: 2rem;
    }
}

.rh span {
    color: #ef4625;
}


.rh {
    /* color:#68174a; */
    color: #ef4625;
    text-align: center;

}

.rh-section {
    padding: 100px 0px 50px 0px;
}

.rh-image {

    margin-top: -150px;

}




/* .why{
    display: flex !important;
    justify-content: center !important;
}
.card{
    flex-basis: 20% !important;
}
@media only screen and (max-width:1000px){
    .why{
flex-direction: column !important;
    }
} */

@media only screen and (max-width:1200px) {
    .main_bt {
        flex-direction: column;
    }
}


@media (min-width:320px) and (max-width:460px)
{
    .problems-job-seekar{
        margin-right: 170px !important;
    }
}

@media (min-width:460px) and (max-width:500px)
{
.problems-job-employee{
    margin-right: 10rem !important;
}
.problems-job-seekar{
    margin-right: 140px !important;
}

}

@media (min-width:400px) and (max-width:480px)
{
.problems-job-seeker{
    margin-left: -5px !important;
}
.problems-job-employee{
    margin-right: 5rem;
}
}

