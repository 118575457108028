/* #ef4625

#68174a

#fbcccc

#fde8eb


*/

@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.Privacy-Policy .pp-heading {
    text-align: center;
    color: #68174a;
    background-color: #fde8eb;
    padding: 3rem 5rem 3rem 5rem;
    font-family: 'new_york_mediumbold';
}

.pp-heading-main {
    padding: 5rem 5rem 3rem 5rem;
    background-color: #fbcccc;
    transform: skew(0deg, -5deg);
}

.cokkkk {
    transform: skew(0deg, 5deg);
}

.ba-ba-com {
    background-color: #fde8eb;
    padding: 90px 0px;
}

.pph {
    color: #ef4625;
    padding-bottom: 2rem;
    font-family: 'new_york_mediumbold';
}

.pph-flex {
    display: flex;
    /* align-items: center; */
    align-items: flex-start;
    /* margin-bottom: 10px; */
}

.pph-hr {
    border: 2px solid #68174a;
    margin-right: 2rem;
    margin-top: -.1rem;
}

.pph-flex-para {
    font-family: 'sf_ui_displayregular';
    border-left: 2px solid #68174a;
    height: auto;
    padding-left: 20px

}



/*git*/


.git-flex2-img111111 {
    position: absolute;
    top: 44px;
    /* right: 11rem; */
    z-index: 2;
}

.git-flex2-img222222 {
    position: absolute;
    top: 11rem;
    right: -19rem;
    z-index: 0;
}
@media only screen and (max-width:1000px){
    .pp-heading-main{
        padding: 3rem 0;
    }
    .pp-heading{
        padding-bottom: 2rem;
    }
    .ba-ba-com {
        /* background-color: #fde8eb; */
        padding: 55px 0px;
        /* margin: 40px 0px; */
    }
   

}