/* 

#ef4625

#68174a

#fbcccc

#fde8eb

*/

@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}



/* dashboard */


.dashboard {
    padding: 2rem;
    background-color: #fde8eb;
    text-align: center;
}


.dashboard-heading-d {
    color: #68174a;
}

/* Profile-Overview */

.Profile-Overview {

    padding: 2rem;
    background-color: #fbcccc;



}

.flex-p-overview {
    background-color: #fde8eb;
    padding: 2rem;
    border-radius: 1rem;

    /* height: 76rem; */
}


.diff-jobs {
    display: flex;
    justify-content: space-around;

    background-color: #fde8eb;
    padding: 2rem;
    border-radius: 1rem;
}

.ver-line {
    border: 2px solid #fbcccc;
    height: 6rem;
}

.num-job {
    text-align: center;
}

.cir-pro-pro-la {
    width: 12rem !important;
    height: 12rem !important;
    color: #ef4625 !important;
}

.pro-com-main {
    background-color: #fde8eb;
    text-align: center;

}

.main-h-j-flex {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.pro-com {
    background-color: white;
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    padding: 2rem;
    border-bottom: 3px solid #ef4625;
    border-right: 3px solid #ef4625;
    /* margin: 2rem; */
}

.pro-com h1 {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.pro-com p {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}


.para-pro-com p {
    padding: 0 2rem 2rem 2rem;
}

.btn-pro-com button {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: white;
    /* margin-right: 5rem; */
    /* margin-left: 1.5em; */
    font-family: 'new_york_mediumbold';
    margin-bottom: 2rem;
}


.pro-over-col {
    color: #ef4625;
}

.icn ion-icon {
    color: #722F37 !important;
    width: 25px;
    height: 25px;
/* background-color: aqua; */
}

/* .bol-pro .vfv{
    width: 29px !important;
    height: 29px !important;
} */

.algn h4{

    padding-left: 0px !important;
   
  
}
.bx-sdw{
 box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
/* .Profile-Snapshot .algn h4 {
    padding-left: 0px !important;
    border: 1px solid red;
  
} */
.bol-pro {
    cursor: pointer;
    font-weight: bold;
    font-family: 'new_york_mediumbold';
    line-height: 30px;
    color: #68174a;
}
/* .bol-pro span{
    position: relative;
    top: -5px;

} */

.bol-pro:hover {
    color: red;
}


.Recent-Applied-Jobs {
    background-color: #fde8eb;
    padding: 2rem;
    border-radius: 1rem;
    margin: 2rem 0;
}

.Recent-Applied-Jobs h4 {
    padding: 1rem;
    color: #68174a;
}

.Trending-jobs {
    background-color: #fde8eb;
    padding: 2rem;
    border-radius: 1rem;
    margin: 2rem 0;

}

.Trending-jobs h4 {
    padding: 1rem;
    color: #68174a;
}

.Profile-Snapshot {
    background-color: #fde8eb;
    padding: 2rem;
    border-radius: 1rem;
    margin: 2rem 0;
}

.Profile-Snapshot h4 {
    padding: 1rem;
    color: #68174a;
}





/* Media Query */

@media screen and (max-width:992px) {
    .flex-p-overview {

        /* height: 25rem; */
        margin-bottom: 3rem;
    }
}