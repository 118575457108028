






/* #ef4625

#68174a

#fbcccc

#fde8eb


*/

@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
         url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
         url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.va-pad{
    background-color: #fde8eb;
    text-align: center;
    padding: 5rem 5rem;
}

.Verify-your-Account{
    
    display: flex;
    justify-content: center;
}


.width-otp{
    width: 35%;
}

.resend{
    background: none;
    border: none;
    color: #ef4625;
    border-bottom: 1px solid #ef4625;
    font-weight: bold;
    margin-top: 1rem;
}

.passwordLogin{
    background: none;
    border: none;
    color: #68174a;
    border-bottom: 1px solid #68174a;
    font-weight: bold;
    margin-top: 1rem;
}

.vya-reg{
    font-family: 'new_york_mediumbold';
}

.vya-veri{
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.vya-veri span{
    color: #ef4625;
}

.vya-para-p{
    font-family: 'sf_ui_displayregular';
}

.vya-otpp{
    
    font-size: 4rem;
}

.in-otp-vc{
    font-size: 2rem !important;
    border: none;
    outline: none;
    text-align: center;
    padding: .4rem;
    letter-spacing: 1.5rem;
    width: 20rem;
}



@media screen and (max-width:414px){

    .width-otp{
        width: 100%;
    }
}

@media screen and (max-width:991px){

    .width-otp{
        width: 100%;
    }
}