

.ChangePassword{
    background-color: #fde8eb;
}

.wChePas{
    text-align: center;
    color: #ef4625;
    margin-top: 5rem;
    font-family: 'new_york_mediumbold';
}

.chen_Pass{
    text-align: center;
    color: #68174a;
    font-family: 'new_york_mediumbold';
}

.chen_Pass span{
    color: #ef4625;
}

.change_pass_form{
    margin: 3rem 5rem;
    background-color: white;
    padding: 3rem;
    border-radius: 10px;
}

.change_pass_form label{
    color: #68174a;
    font-weight: bold;
    font-family: 'new_york_mediumbold';
}

.change_pass_form input{
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #68174a;
    outline: none;
    font-family: 'new_york_mediumbold';
}

.chem_pass_btn{
    width: 100%;
    color: white;
    border-radius: 5px;
    padding: 1rem 0.6rem;
    margin: .5rem 0 .5rem 0;
    border: 1px solid #ef4625;
    background-color: #ef4625;
    font-family: 'new_york_mediumbold';
}