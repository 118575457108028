


.g-i-avtar{
    width: 7rem;
    height: 7rem;
    border: 2px solid #68174a;
    border-radius: 50%;
    margin-right: 2rem;
}

.my-pro-pad-mar{
    display: flex;
    align-items: center;
    color: #68174a;
}


.pad-mar-my-proo{
    background-color: #fde8eb;
    padding: 2rem;
    border-radius: 1rem;
}

.box-pad-mar-k{
    margin: 2rem 0;
}


.my-pro-inn-pro{
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
}


.edi-form{
    display: flex;
    justify-content: right;
}
.edit_form{
    padding: .5rem;
    /* border: 1px solid #68174a; */
    border-radius: 5px;
    color: white;
    /* margin-left: 1rem; */
    margin: 0 0.7rem 1rem 0;
    cursor: pointer;
}


/* .edit_form:hover{
    color: #ef4625;
    border: 1px solid #ef4625;
} */

/* .edit_form:active{
    background-color: #68174a;
} */

.input-for-m{
    width: 100%;
    padding: .5rem .2rem;
    border-radius: 5px;
    outline: none;
    border: 1px solid gray;
}

.p-para-my{
    text-align: center;
    padding: 1rem;
}

.link-icn-my{
    width: 100%;
    text-transform: capitalize !important;
    color: #ef4625 !important;
    background-color: white !important;
}

.btn-my-pro-mj{
    padding: 10px 25px; 
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: white;
    /* margin-right: 5rem; */
    /* margin-left: 1.5em; */
    font-family: 'new_york_mediumbold';
    width: 100%;
}

.cent{
    text-align: center;
}

.label-all-pro{
    font-weight: bold;
    color: #68174a;
    margin-top: .5rem;
}

.flex-p-overview-my-pro{
    background-color: #fde8eb;
    padding: 2rem;
    border-radius: 1rem;
    height: 47rem;
    margin-bottom: 2rem;
}

.btn-learn-b-m{
    margin: 1rem 0;
    float: right;
}

.mbb-1{
    margin-top: 1.5rem;
}

/* media query */


@media screen and (max-width: 992px)  {
    .label-all-pro {
        height: 3rem;
    }
}

@media screen and (max-width: 767px) {
    .label-all-pro {
        height: unset;
    }
    .edit_form{
        font-size:15px;
        display: flex;
        justify-content: space-between !important;
    }
    .box-pad-mar-k{
        width: 80% !important;
        font-size: 10px;
        margin-left: -1rem;
    }

   
}