





.cn-cn{
    margin-top: 1rem;
}

.fr-geg-para-dev{
    text-align: center;
}

.ecpe-k-select label{
    float: left;
    /* padding-left: 5%; */
    margin: 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.ecpe-k-select select{
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: right; */
    border-radius: 5px;
}


.please-note-four{
    margin: 1rem 0 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}


.cl-yes-no label{
    float: left;
    /* padding-left: 5%; */
    margin: 0 0 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.cl-yes-no select{
    width: 100%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    border: 1px solid gray ;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: right; */
    border-radius: 5px;
}

/* .can-id-hh{
    margin-top: 1rem !important;
} */

.cl-yes-no input{
    width: 100%;
    padding: .6rem !important;
    background-color: white !important;
    color: gray !important;
    border: 1px solid gray !important;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: right; */
    border-radius: 5px;
}

.col-num-phon label{
    float: left;
    /* padding-left: 5%; */
    margin: 0 0 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.col-num-phon input{
    width: 100%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    border: 1px solid gray;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: right; */
    border-radius: 5px;
}

.col-num-phonn label{
    float: left;
    /* padding-left: 5%; */
    margin: 1rem 0 1rem 0;
    font-family: 'new_york_mediumbold';
    color: #68174a;
}

.col-num-phonn input{
    width: 100%;
    padding: .6rem !important;
    /* background-color: white !important; */
    color: gray !important;
    border: 1px solid gray;
    /* text-transform: capitalize !important; */
    /* font-weight: bold !important; */
    outline: none !important;
    /* box-shadow: 0px 0px 2px 1px gray !important; */
    /* float: right; */
    border-radius: 5px;
    margin-bottom: 1rem;
}

.ind-ind-mec{
    margin-top: 1rem;
}

@media (max-width:420px){
    .range-slider-in-lak{
        margin-top: 10px !important;
    }
}