.jobCardMainConatiner {
    /* box-shadow: 0 0 0px rgba(0, 0, 0, 0.2); */
    background-color: white;
    width: 200px;
    /* height: 400px; */
    max-height: 450px;
    /* min-height: 450px; */
    min-height: 480px;
    padding: 5px 20px;
    flex-basis: 24%;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin-left: 10px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, .2);
    position: relative;
}

.jobCardImageContainer {
    max-width: 100px;
}
.card_patagrap{
    margin-top: 30px;
}

/* @media (min-width:300px) and (max-width:768px)
{
    .jobCardMainConatiner{
        max-width: 90% !important;
    }
} */


.jobCardHeaderContainer {
    display: flex;
    align-items: center;
}

.jobCardDescription {
    color: gray;
    font-size: 12px;
}

.jobCardDescriptionBox {
    /* color: ; */
    margin-top: 20px;
}

.jobBelowContainer h3 {
    color: #ef4625;
    font-weight: 100px !important;
    font-size: 18px !important;
}

.jobCardFooter {
        display: flex !important;
            justify-content: space-between !important;
            align-items: center;
            width: 75% !important;
            bottom: 5px !important;
}

/* * {
    border: 1px solid black;
} */

.jobCardDescription {
    display: flex;
    align-items: center;
}

.jobCardTitle {
    height: 60px;
}

.jobCardTitleContainer {


    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    color: gray;
    font-size: 14px;
}

.jobBelowContainer {
    margin-top: 20px;
}

.boldText {
    font-size: 16px;
    font-weight: bold !important;
}

.jobCardComanyLocation {
    color: gray;
    font-size: 14px;
}

.jobCardRightContainer {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.jobCardImage {
    width: 50px;
    object-fit: cover;

}
@media only screen and (max-width:1200px){
    .jobCardMainConatiner {
    background-color: white;
    max-width: 280px;
    /* height: 400px; */
    max-height: 550px;
    /* min-height: 450px; */
    min-height: 550px;
    padding: 20px 20px;
    flex-basis: 24%;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, .2);
}

.jobCardImageContainer {
    max-width: 50px;
    min-width: 50px;
}


.jobCardHeaderContainer {
    display: flex;
    align-items: center;
}

.jobCardDescription {
    color: gray;
    font-size: 14px;
}

.jobCardDescriptionBox {
    /* color: ; */
    margin-top: 30px;
}

.jobBelowContainer h3 {
    color: #ef4625;
    font-weight: 100px !important;
    font-size: 18px !important;
}

.jobCardFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* * {
    border: 1px solid black;
} */

.jobCardDescription {
    display: flex;
    align-items: center;
}

.jobCardTitle {
    height: 80px;
}

.jobCardTitleContainer {


    display: flex;
    justify-content: space-between;
    width: 120%;
    align-items: center;
    color: gray;
    font-size: 14px;
}

.jobBelowContainer {
    margin-top: 20px;
}

.boldText {
    font-size: 10px;
    font-weight: bold !important;
}

.jobCardComanyLocation {
    color: gray;
    font-size: 14px;
}

.jobCardRightContainer {
    /* margin-left: 10px; */
    /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
}

.jobCardImage {
    width: 50px;
    object-fit: cover;

}
}

.Days-age{
    margin-right: 1rem;
}

