@font-face {
    font-family: 'new_york_mediumbold';
    src: url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-newyorkmediumbold-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-sf-ui-display-regular-1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




* {
    margin: 0;
    padding: 0;
}

header {
    background-color: #fde8eb;
    color: black;
}

.containe {
    width: 100%;
    position: relative;
    /* padding: 3rem 0 3rem 5rem; */
    /* margin: 0 4rem; */
    /* max-width: 1200px; */
}

.h3-logo-here {
    color: #68174a;
    font-family: 'new_york_mediumbold';
}

.container-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 275px;
    /* padding: .5em 0; */
    /* margin-left: 5rem; */
    cursor: pointer;
    /* background-color: red; */
    margin-left: -34px;

}

.list {
    display: flex;
}

.listItem {
    margin-left: 1.5em;
    /* color: black; */
    color: #68174a;
    text-decoration: none;
    /* font: 1.1em; */
    font: 1.4em;
    font-family: 'new_york_mediumbold';
}

.listItem:hover {
    /* color: rgb(253, 1, 253); */
    /* color: #68174a; */
    color: #ef4625;
    /* transition: ease-in-out 200ms; */
    /* border-bottom: .1em double #68174a; */
    border-bottom: .1em double #ef4625;
}

.active {
    /* color: #68174a !important; */
    color: #ef4625 !important;
    /* border-bottom: .1em double #68174a; */
    border-bottom: .1em double #ef4625;
}

.active :hover {
    cursor: pointer;
}

.ham-hide {
    display: none !important;
}

.btns {
    display: flex;
    float: right;
}

.bttn {
    /* color: white; */
    font-size: 1.1rem !important;

    /* margin-left: 1.5rem; */
    /* padding: 10px 25px; */
    /* border-radius: 10px; */
}

.wbkk {
    border: none;
    background: none;
    border-bottom: .1em double #68174a;
    color: #68174a;
    font-family: 'new_york_mediumbold';
    /* font-weight: bold; */
    /* margin-right: 1rem; */
}

.wbkkk {
    border: 1px solid #68174a;
    background: none;
    padding: 10px 25px;
    border-radius: 10px;
    color: #68174a;
    font-family: 'new_york_mediumbold';
}

.bkb {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #68174a;
    color: #fde8eb;
    /* margin-right: 5rem; */
    margin-left: 1.5em;
    font-family: 'new_york_mediumbold';
}

.bk {
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ef4625;
    color: white;
    /* margin-right: 5rem; */
    margin-left: 1.5em;
    font-family: 'new_york_mediumbold';
}

.hide {
    display: none;
}

/* media queries */

@media screen and (max-width:1200px) {
    .listItem {
        margin-left: 1rem;

        font: .9rem;
        /* word-spacing: 20px; */
        /* line-height: 30px !important; */

    }
   
}

@media screen and (max-width:998px) {
    .ham-hide {
        display: block !important;
    }

    .list,
    .btn-hide {
        display: none;
    }

    .nav-links-mobile {
        position: absolute;
        display: flex;
        list-style: none;
        background-color: #fbcccc;
        left: 0;
        top: 85px;
        transition: all 0.5s ease-out;
        width: 100%;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        padding: 2rem;
        z-index: 10;
    }

    .nav-links-mobilee {
        position: absolute;
        display: flex;
        list-style: none;
        background-color: #fbcccc;
        left: 0;
        top: 235px;
        padding-top: 0.5rem;
        padding-bottom: 2rem;
        transition: all 0.5s ease-out;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;
    }

    .wbkkk {
        border: unset;
        background: none;
        padding: unset;
        border-radius: 10px;
        color: #68174a;
        font-family: 'new_york_mediumbold';
    }

    .bk {
        padding: unset;
        border-radius: unset;
        border: none;
        background-color: unset;
        color: #68174a;
        /* margin-right: 5rem; */
        margin-left: unset;
        font-family: 'new_york_mediumbold';
    }

    .bkb {
        padding: unset;
        border-radius: unset;
        border: none;
        background-color: unset;
        color: #68174a;
        /* margin-right: 5rem; */
        margin-left: unset;
        font-family: 'new_york_mediumbold';
    }

}

/* @media screen and (max-width:768px) {
    html{
        font-size: 13px;
    }

    .container-flex{
        flex-direction: column;
    }

    .btns{
        flex-direction: column;
    }

    .logo{
        width: 100px;
        margin: 1rem auto;
    }

    .wbk{
        border: none;
        background: none;
        border-bottom: none;
        margin-left: -22px;
        
    }

    .bk{
        margin: 0;
    }

    .active{
        border-bottom: none;
    }
    
}

@media screen and (max-width:568px) {
    html{
        font-size: 11px;
    }

    .list{
        flex-direction: column;
    }

    .btns{
        flex-direction: column;
    }

    .bttn{
        margin-top: .2em;
    }

    .bk{
        margin-bottom: 1em;
    }

    .listItem{
        margin-top: .2em;
    }

} */